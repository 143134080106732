import { all } from 'redux-saga/effects';
import AppSagas from './app/saga';
import AuthSagas from './auth/saga';
import ConfigSagas from './config/saga';
import AlunosSagas from './alunos/saga';
import RegistrationsSagas from './registrations/saga';
import VendasSagas from './venda/saga';
import StatementSagas from './statement/saga';
import VehicleSaga from './vehicle/saga';
import PeriodSaga from './period/saga';
import LeadsSagas from './leads/saga';
import ScheduleSagas from './practice/saga';
import ServicesSagas from './services/saga';
import ConfigScheduleSagas from './schedule/saga';
import PermissionsSagas from './permission/saga';

export default function* rootSaga() {
  yield all([
    AppSagas(),
    AuthSagas(),
    ConfigSagas(),
    AlunosSagas(),
    VendasSagas(),
    VehicleSaga(),
    PeriodSaga(),
    LeadsSagas(),
    ServicesSagas(),
    StatementSagas(),
    ScheduleSagas(),
    ConfigScheduleSagas(),
    RegistrationsSagas(),
    PermissionsSagas(),
  ]);
}
