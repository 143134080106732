import actions from './actions';

const initialState = {
  listVehicles: {
    data: [],
    meta: [],
    request: false,
  },
  vehicle: null,
  vehicleID: null,
};

export default function VehiclesReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_LIST_VEHICLES_SUCCESS: {
      return {
        ...state,
        listVehicles: {
          request: true,
          pageController: action.payload.data.length,
          meta: action.payload.meta,
          data:
            action.payload.meta.current_page > 1
              ? [...state.listVehicles.data, ...action.payload.data]
              : action.payload.data,
        },
        vehicle: null,
        vehicleID: null,
      };
    }

    case actions.SET_VEHICLE: {
      return {
        ...state,
        vehicleID: action.payload,
      };
    }

    case actions.FETCH_VEHICLE_SUCCESS: {
      return {
        ...state,
        vehicle: action.payload,
      };
    }

    default:
      return state;
  }
}
