import actions from './actions';

const initialState = {
  listAlunos: {
    data: [],
    meta: { current_page: 1 },
    pageController: 0,
  },
  isLoadingList: true,
  listCNHS: {
    data: [],
    meta: [],
  },
  listFinanceiro: {
    data: [],
    meta: [],
    extra: {},
  },
  listFiles: {
    data: [],
    meta: [],
  },
  alunos: {},
  alunoID: null,
  filtros: {
    status_select: {
      key: 0,
      value: 'Ativo',
      label: 'Ativo',
    },
  },
};

export default function AlunosReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.SET_IS_LOADING_LIST: {
      return {
        ...state,
        isLoadingList: action.payload
      }
    }

    case actions.FETCH_LIST_ALUNOS_SUCCESS: {
      return {
        ...state,
        isLoadingList: false,
        listAlunos: {
          meta: action.payload.meta,
          pageController: action.payload.data.length,
          data:
            action.payload.meta.current_page > 1
              ? [...state.listAlunos.data, ...action.payload.data]
              : action.payload.data,
        },
      };
    }

    case actions.INSERT_ALUNO_SUCCESS: {
      return { ...state, alunoID: action.payload.data.codigo };
    }

    case actions.FETCH_ALUNO_SUCCESS: {
      return {
        ...state,
        alunos: action.payload,
        alunoID: action.payload.data.codigo,
      };
    }

    case actions.UPDATE_ALUNO_SUCCESS: {
      return {
        ...state,
        alunos: action.payload,
        alunoID: action.payload.data.codigo,
      };
    }

    case actions.SET_FILTROS: {
      return {
        ...state,
        filtros: action.payload,
      };
    }

    case actions.DELETE_ALUNO_SUCCESS: {
      return { ...state, alunos: action.payload };
    }

    case actions.INACTIVE_ALUNO_SUCCESS: {
      return { ...state, alunos: action.payload };
    }

    case actions.SET_ALUNO_ID: {
      return {
        ...state,
        alunoID: action.payload,
      };
    }

    case actions.INSERT_CNH_SUCCESS: {
      return { ...state };
    }

    case actions.UPDATE_CNH_SUCCESS: {
      return { ...state };
    }

    case actions.DELETE_CNH_SUCCESS: {
      return { ...state };
    }

    case actions.INSERT_RENACH_SUCCESS: {
      return { ...state };
    }

    case actions.COMPLETE_PROCESSO_ALUNO_SUCCESS: {
      return { ...state };
    }

    case actions.REABRIR_PROCESSO_ALUNO_SUCCESS: {
      return { ...state };
    }

    case actions.REMOVER_PROCESSO_ALUNO_SUCCESS: {
      return { ...state };
    }

    case actions.FETCH_LIST_CNHS_SUCCESS: {
      return {
        ...state,
        listCNHS: {
          data: action.payload.data,
          meta: action.payload.meta,
        },
      };
    }

    case actions.FETCH_LIST_FINANCEIRO_ALUNO_SUCCESS: {
      return {
        ...state,
        listFinanceiro: {
          data: action.payload.data,
          meta: action.payload.meta,
          extra: action.payload.extra,
        },
      };
    }

    case actions.FETCH_LIST_FILES_SUCCESS: {
      return {
        ...state,
        listFiles: {
          data: action.payload.data,
          meta: action.payload.meta,
        },
      };
    }

    default:
      return state;
  }
}
