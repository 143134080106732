import { combineReducers } from 'redux';
import AppReducer from './app/reducer';
import AuthReducer from './auth/reducer';
import ConfigReducer from './config/reducer';
import AlunosReducer from './alunos/reducer';
import RegistrationsReducer from './registrations/reducer';
import VendaReducer from './venda/reducer';
import StatementReducer from './statement/reducer';
import LeadsReducer from './leads/reducer';
import VehiclesReducer from './vehicle/reducer';
import PeriodReducer from './period/reducer';
import ScheduleReducer from './practice/reducer';
import ServicesReducer from './services/reducer';
import ConfigScheduleReducer from './schedule/reducer';
import PermissionReducer from './permission/reducer';

export default combineReducers({
  AppReducer,
  AuthReducer,
  ConfigReducer,
  AlunosReducer,
  VendaReducer,
  LeadsReducer,
  VehiclesReducer,
  StatementReducer,
  ScheduleReducer,
  ServicesReducer,
  ConfigScheduleReducer,
  PeriodReducer,
  RegistrationsReducer,
  PermissionReducer,
});
