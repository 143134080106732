import actions from './actions';

const initialState = {
  listLeads: {
    data: [],
    meta: [],
  },
  Lead: {},
  Contact: { data: [] },
};

export default function LeadsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_LIST_LEADS_SUCCESS: {
      return {
        ...state,
        listLeads: {
          data: action.payload.data,
          meta: action.payload.meta,
        },
      };
    }

    case actions.LOSE_LEAD_SUCCESS: {
      return { ...state };
    }

    case actions.DELETE_LEAD_SUCCESS: {
      return { ...state };
    }

    case actions.FETCH_LEAD_SUCCESS: {
      return { ...state, Lead: action.payload.data };
    }

    case actions.FETCH_LEAD_CONTACT_SUCCESS: {
      return {
        ...state,
        Contact: {
          data: action.payload.data,
        },
      };
    }

    default:
      return state;
  }
}
