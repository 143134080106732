import { UserProps, EmployeeProps, UserPropsInsert, ContractProps } from './types';
import { Options } from '../types';

const actions = {
  FETCH_LIST_PROVIDERS_REQUEST: 'FETCH_LIST_PROVIDERS_REQUEST',
  FETCH_LIST_PROVIDERS_SUCCESS: 'FETCH_LIST_PROVIDERS_SUCCESS',
  FETCH_LIST_CONTRACTS_REQUEST: 'FETCH_LIST_CONTRACTS_REQUEST',
  FETCH_LIST_CONTRACTS_SUCCESS: 'FETCH_LIST_CONTRACTS_SUCCESS',
  FETCH_CONTRACT_REQUEST: 'FETCH_CONTRACT_REQUEST',
  FETCH_CONTRACT_SUCCESS: 'FETCH_CONTRACT_SUCCESS',
  FETCH_PRINT_REQUEST: 'FETCH_PRINT_REQUEST',
  FETCH_PRINT_SUCCESS: 'FETCH_PRINT_SUCCESS',
  FETCH_LIST_PRINTS_REQUEST: 'FETCH_LIST_PRINTS_REQUEST',
  FETCH_LIST_PRINTS_SUCCESS: 'FETCH_LIST_PRINTS_SUCCESS',
  DELETE_CONTRACT_REQUEST: 'DELETE_CONTRACT_REQUEST',
  DELETE_CONTRACT_SUCCESS: 'DELETE_CONTRACT_SUCCESS',
  DELETE_PRINT_REQUEST: 'DELETE_PRINT_REQUEST',
  DELETE_PRINT_SUCCESS: 'DELETE_PRINT_SUCCESS',
  INACTIVE_EMPLOYEE_REQUEST: 'INACTIVE_EMPLOYEE_REQUEST',
  INACTIVE_EMPLOYEE_SUCCESS: 'INACTIVE_EMPLOYEE_SUCCESS',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  INSERT_EMPLOYEE_REQUEST: 'INSERT_EMPLOYEE_REQUEST',
  INSERT_EMPLOYEE_SUCCESS: 'INSERT_EMPLOYEE_SUCCESS',
  UPDATE_EMPLOYEE_REQUEST: 'UPDATE_EMPLOYEE_REQUEST',
  UPDATE_EMPLOYEE_SUCCESS: 'INSERT_EMPLOYEE_SUCCESS',
  DELETE_EMPLOYEE_REQUEST: 'DELETE_EMPLOYEE_REQUEST',
  DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
  PAGE_DATA_REQUEST: 'PAGE_DATA_REQUEST',
  PAGE_DATA_SUCCESS: 'PAGE_DATA_SUCCESS',
  FETCH_LIST_EMPLOYEES_REGISTER_REQUEST: 'FETCH_LIST_EMPLOYEES_REGISTER_REQUEST',
  FETCH_LIST_EMPLOYEES_REGISTER_SUCCESS: 'FETCH_LIST_EMPLOYEES_REGISTER_SUCCESS',
  SET_EMPLOYEE: 'SET_EMPLOYEE',
  INSERT_LOGO: 'INSERT_LOGO',
  INSERT_ICON: 'INSERT_ICON',
  INSERT_BANNER: 'INSERT_BANNER',
  INSERT_MARKER: 'INSERT_MARKER',
  SET_CONTRACT: 'SET_CONTRACT',
  SET_PRINT: 'SET_PRINT',
  FETCH_CONDICAO_PAGAMENTO_REQUEST: 'FETCH_CONDICAO_PAGAMENTO_REQUEST',
  FETCH_CONDICAO_PAGAMENTO_SUCCESS: 'FETCH_CONDICAO_PAGAMENTO_SUCCESS',

  fetchPageData: () => ({
    type: actions.PAGE_DATA_REQUEST,
  }),

  fetchCondicaoPagamento: (loadingFn?: (loading: boolean) => void) => ({
    type: actions.FETCH_CONDICAO_PAGAMENTO_REQUEST,
    loadingFn,
  }),

  setCondicaoPagamento: (data: any) => ({
    type: actions.FETCH_CONDICAO_PAGAMENTO_SUCCESS,
    payload: { data },
  }),

  insertLogo: (logo: string) => ({
    type: actions.INSERT_LOGO,
    payload: logo,
  }),

  insertIcon: (icon: string) => ({
    type: actions.INSERT_ICON,
    payload: icon,
  }),

  insertBanner: (banner: string) => ({
    type: actions.INSERT_BANNER,
    payload: banner,
  }),

  insertMarker: (payload: { lat: number; lng: number }) => ({
    type: actions.INSERT_MARKER,
    payload: payload,
  }),

  setContract: (payload: number) => ({
    type: actions.SET_CONTRACT,
    payload: payload,
  }),

  setPrint: (payload: number) => ({
    type: actions.SET_PRINT,
    payload: payload,
  }),

  setEmployee: (payload: number) => ({
    type: actions.SET_EMPLOYEE,
    payload: payload,
  }),

  fetchListRegisterEmployees: (payload: Options) => ({
    type: actions.FETCH_LIST_EMPLOYEES_REGISTER_REQUEST,
    payload,
  }),

  changePassword: (
    password: { password: string; confirm_password: string },
    userID: number,
    callback: () => void
  ) => ({
    type: actions.CHANGE_PASSWORD_REQUEST,
    payload: password,
    userID: userID,
    callback,
  }),

  fetchListContracts: (payload: Options) => ({
    type: actions.FETCH_LIST_CONTRACTS_REQUEST,
    payload,
  }),

  fetchContract: (payload: Number) => ({
    type: actions.FETCH_CONTRACT_REQUEST,
    payload,
  }),

  fetchPrint: (payload: Number) => ({
    type: actions.FETCH_PRINT_REQUEST,
    payload,
  }),

  fetchListPrints: (payload: Options) => ({
    type: actions.FETCH_LIST_PRINTS_REQUEST,
    payload,
  }),

  fetchListProviders: (payload: Options) => ({
    type: actions.FETCH_LIST_PROVIDERS_REQUEST,
    payload,
  }),

  insertEmployee: (payload: EmployeeProps, callback: (payload: any) => void) => ({
    type: actions.INSERT_EMPLOYEE_REQUEST,
    payload,
    callback,
  }),

  updateEmployee: (
    payload: EmployeeProps,
    id: number,
    callback: (payload: any) => void
  ) => ({
    type: actions.UPDATE_EMPLOYEE_REQUEST,
    payload,
    id,
    callback,
  }),

  inactiveEmployee: (
    user: number,
    status: number,
    params: UserProps,
    callback: () => void
  ) => ({
    type: actions.INACTIVE_EMPLOYEE_REQUEST,
    payload: user,
    status: status,
    params: params,
    callback,
  }),

  deleteEmployee: (user: number, params: UserProps, callback: () => void) => ({
    type: actions.DELETE_EMPLOYEE_REQUEST,
    payload: user,
    params: params,
    callback,
  }),

  deleteContract: (
    contract: number,
    params: ContractProps,
    callback: () => void
  ) => ({
    type: actions.DELETE_CONTRACT_REQUEST,
    payload: contract,
    params: params,
    callback,
  }),

  deletePrint: (print: number, params: ContractProps, callback: () => void) => ({
    type: actions.DELETE_PRINT_REQUEST,
    payload: print,
    params: params,
    callback,
  }),
};
export default actions;
