import {
  all,
  takeEvery,
  put,
  call,
  fork,
  spawn,
  select,
} from 'redux-saga/effects';

import appActions from '../app/actions';
import actions from './actions';
import api from '../../helpers/api';

import { Meta } from '../types';
import {
  actionFetchListLeadsRequest,
  actionLoseLeadRequest,
  actionFetchLeadRequest,
  actionDeleteLeadRequest,
  actionSuccess,
  actionfetchContactsLeadRequest,
  actionInsertContactLeadRequest,
  actionInsertLeadRequest,
} from './types';

export const getListLeads = (state: any) =>
  state.LeadsReducer.listLeads;

export function* getListagemLeadsRequest() {
  yield takeEvery(
    actions.FETCH_LIST_LEADS_REQUEST,
    function* ({ payload }: actionFetchListLeadsRequest) {
      try {
        let list: { meta: Meta; data: any } = yield select(
          getListLeads
        );

        const result: { meta: Meta; data: any } = yield call(
          api.fetchListLeads,
          payload
        );
        if (
          (result.meta && result.meta.current_page === 1) ||
          !list.data
        ) {
          yield put({
            type: actions.FETCH_LIST_LEADS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_LIST_LEADS_SUCCESS,
            payload: {
              ...list,
              data: [...list.data, ...result.data],
              meta: result.meta,
            },
          });
        }
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertLeadRequest() {
  yield takeEvery(
    actions.INSERT_LEAD_REQUEST,
    function* ({ payload, callback }: actionInsertLeadRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: any = yield call(api.insertLead, payload);
        yield put({
          type: actions.INSERT_LEAD_SUCCESS,
          payload: result,
        });
        callback();
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* loseLeadRequest() {
  yield takeEvery(
    actions.LOSE_LEAD_REQUEST,
    function* ({ payload, leadID, callback }: actionLoseLeadRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: any = yield call(api.loseLead, payload, leadID);
        yield put({
          type: actions.LOSE_LEAD_SUCCESS,
          payload: result,
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
        callback();
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* fetchLeadRequest() {
  yield takeEvery(
    actions.FETCH_LEAD_REQUEST,
    function* ({ leadID }: actionFetchLeadRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.fetchLead, leadID);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.FETCH_LEAD_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertContactLeadRequest() {
  yield takeEvery(
    actions.INSERT_LEAD_CONTACT_REQUEST,
    function* ({
      payload,
      callback,
    }: actionInsertContactLeadRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: any = yield call(api.insertContactLead, payload);
        yield put({
          type: actions.INSERT_LEAD_CONTACT_SUCCESS,
          payload: result,
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
        if (result.data) {
          callback();
        }
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* fetchContactsLeadRequest() {
  yield takeEvery(
    actions.FETCH_LEAD_CONTACT_REQUEST,
    function* ({ leadID }: actionfetchContactsLeadRequest) {
      try {
        const result: any = yield call(api.fetchContactsLead, leadID);
        yield put({
          type: actions.FETCH_LEAD_CONTACT_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertContactLeadSuccess() {
  yield takeEvery(
    actions.INSERT_LEAD_CONTACT_SUCCESS,
    function* ({ payload }: any) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Sucesso' },
      });
      yield put({
        type: actions.FETCH_LEAD_CONTACT_REQUEST,
        leadID: payload.data.lead,
      });
    }
  );
}

export function* deleteLeadRequest() {
  yield takeEvery(
    actions.DELETE_LEAD_REQUEST,
    function* ({
      payload,
      params,
      callback,
    }: actionDeleteLeadRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.deleteLead, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.DELETE_LEAD_SUCCESS,
          payload: params,
        });

        if (typeof callback == 'function') {
          callback();
        }
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deleteLeadSuccess() {
  yield takeEvery(
    actions.DELETE_LEAD_SUCCESS,
    function* ({ payload }: actionSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Sucesso' },
      });
      yield put({
        type: actions.FETCH_LIST_LEADS_REQUEST,
        payload: payload,
      });
    }
  );
}

export function* getLeadsSuccess() {
  yield takeEvery(
    actions.FETCH_LIST_LEADS_SUCCESS,
    function* ({ payload }: actionSuccess) {
      yield;
    }
  );
}

export function* loseLeadSuccess() {
  yield takeEvery(
    actions.LOSE_LEAD_SUCCESS,
    function* ({ payload }: actionSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Sucesso' },
      });
      yield put({
        type: actions.FETCH_LIST_LEADS_REQUEST,
        payload: { page: 1, situacao: 0 },
      });
    }
  );
}

export function* insertLeadSuccess() {
  yield takeEvery(
    actions.INSERT_LEAD_SUCCESS,
    function* ({ payload }: actionSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Sucesso' },
      });
      yield put({
        type: actions.FETCH_LIST_LEADS_REQUEST,
        payload: { page: 1, situacao: 0 },
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(getListagemLeadsRequest),
    spawn(fetchContactsLeadRequest),
    spawn(insertContactLeadRequest),
    spawn(deleteLeadRequest),
    spawn(insertLeadRequest),
    spawn(loseLeadRequest),
    spawn(fetchLeadRequest),
    fork(insertContactLeadSuccess),
    fork(getLeadsSuccess),
    fork(insertLeadSuccess),
    fork(deleteLeadSuccess),
    fork(loseLeadSuccess),
  ]);
}
