import { all, takeEvery, put, call, fork, spawn, select } from 'redux-saga/effects';

import appActions from '../app/actions';
import actions from './actions';
import api from '../../helpers/api';

import {
  actionDeletePeriodRequest,
  actionFetchListPeriodsRequest,
  actionFetchPeriodRequest,
  actionInsertPeriodRequest,
} from './types';
import { actionSuccess } from '../leads/types';
import { Meta } from '../types';

export const getListPeriods = (state: any) => state.PeriodReducer.listPeriods;

export function* getListagemPeriodsRequest() {
  yield takeEvery(
    actions.FETCH_LIST_PERIODS_REQUEST,
    function* ({ payload }: actionFetchListPeriodsRequest) {
      try {
        const result: Record<string, any> = yield call(
          api.fetchListPeriods,
          payload
        );

        yield put({
          type: actions.FETCH_LIST_PERIODS_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* fetchPeriodRequest() {
  yield takeEvery(
    actions.FETCH_PERIOD_REQUEST,
    function* ({ payload }: actionFetchPeriodRequest) {
      try {
        const result: { meta: Meta; data: any } = yield call(
          api.fetchPeriod,
          payload
        );
        yield put({
          type: actions.FETCH_PERIOD_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertPeriodRequest() {
  yield takeEvery(
    actions.INSERT_PERIOD_REQUEST,
    function* ({ payload, callback }: actionInsertPeriodRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: any = yield call(api.insertPeriod, payload);

        yield put({
          type: actions.INSERT_PERIOD_SUCCESS,
          payload: result,
        });
        if (typeof callback == 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* updatePeriodRequest() {
  yield takeEvery(
    actions.UPDATE_PERIOD_REQUEST,
    function* ({ payload, callback }: actionInsertPeriodRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: any = yield call(api.updatePeriod, payload);

        yield put({
          type: actions.UPDATE_PERIOD_SUCCESS,
          payload: result,
        });
        if (typeof callback == 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deletePeriodRequest() {
  yield takeEvery(
    actions.DELETE_PERIOD_REQUEST,
    function* ({ payload, params, callback }: actionDeletePeriodRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.deletePeriod, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });

        yield put({
          type: actions.DELETE_PERIOD_SUCCESS,
          payload: params,
        });
        callback();
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertPeriodSuccess() {
  yield takeEvery(actions.INSERT_PERIOD_SUCCESS, function* ({}: actionSuccess) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Sucesso' },
    });
  });
}

export function* updatePeriodSuccess() {
  yield takeEvery(actions.INSERT_PERIOD_SUCCESS, function* ({}: actionSuccess) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Sucesso' },
    });
  });
}

export function* deletePerioddSuccess() {
  yield takeEvery(
    actions.DELETE_PERIOD_SUCCESS,
    function* ({ payload }: actionSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Sucesso' },
      });
      yield put({
        type: actions.FETCH_LIST_PERIODS_REQUEST,
        payload: payload,
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(getListagemPeriodsRequest),
    spawn(fetchPeriodRequest),
    spawn(insertPeriodRequest),
    spawn(updatePeriodRequest),
    spawn(deletePeriodRequest),
    fork(insertPeriodSuccess),
    fork(deletePerioddSuccess),
    fork(updatePeriodSuccess),
  ]);
}
