import { all, takeEvery, put, call, fork, spawn } from 'redux-saga/effects';

import appActions from '../app/actions';
import actions from './actions';
import api from '../../helpers/api';

import {
  actionDeleteScheduleRequest,
  actionFetchScheduleRequest,
  actionInsertScheduleRequest,
  actionUpdateScheduleRequest,
} from './types';
import { actionSuccess } from '../leads/types';

export const getListExams = (state: any) => state.ScheduleReducer.listExams;

export function* fetchExamsRequest() {
  yield takeEvery(actions.FETCH_EXAM_REQUEST, function* ({ payload }: any) {
    try {
      const result: Record<string, any> = yield call(api.fetchExamSchedule, payload);

      yield put({
        type: actions.FETCH_EXAM_SUCCESS,
        payload: result,
      });
    } catch (error) {
      yield put({
        type: appActions.HANDLE_ERRORS,
        payload: error,
      });
    }
  });
}

export function* fetchScheduleRequest() {
  yield takeEvery(
    actions.FETCH_SCHEDULE_REQUEST,
    function* ({}: actionFetchScheduleRequest) {
      try {
        const result: any = yield call(api.fetchSchedule);
        yield put({
          type: actions.FETCH_SCHEDULE_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertScheduleRequest() {
  yield takeEvery(
    actions.INSERT_SCHEDULE_REQUEST,
    function* ({ payload, callback }: actionInsertScheduleRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: any = yield call(api.insertSchedule, payload);

        yield put({
          type: actions.INSERT_SCHEDULE_SUCCESS,
          payload: result,
        });
        if (typeof callback == 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* updateScheduleRequest() {
  yield takeEvery(
    actions.UPDATE_SCHEDULE_REQUEST,
    function* ({ payload, callback }: actionUpdateScheduleRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: any = yield call(api.updateSchedule, payload);
        yield put({
          type: actions.INSERT_SCHEDULE_SUCCESS,
          payload: result,
        });
        if (typeof callback == 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deleteScheduleRequest() {
  yield takeEvery(
    actions.DELETE_SCHEDULE_REQUEST,
    function* ({ payload, callback }: actionDeleteScheduleRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.deleteSchedule, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.INSERT_SCHEDULE_SUCCESS,
        });
        callback();
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertScheduleSuccess() {
  yield takeEvery(actions.INSERT_SCHEDULE_SUCCESS, function* ({}: actionSuccess) {
    yield put({
      type: actions.FETCH_SCHEDULE_REQUEST,
    });
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        message: 'Configurações da agenda prática salvas',
      },
    });
  });
}

export default function* rootSaga() {
  yield all([
    spawn(fetchScheduleRequest),
    spawn(fetchExamsRequest),
    spawn(insertScheduleRequest),
    spawn(updateScheduleRequest),
    spawn(deleteScheduleRequest),
    fork(insertScheduleSuccess),
  ]);
}
