import actions from './actions';

const initialState = {
  schedule: null,
  listExams: {
    data: [],
    pageController: 0,
    meta: { current_page: 0 },
  },
};

export default function ScheduleReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case actions.FETCH_SCHEDULE_SUCCESS: {
      return {
        ...state,
        schedule: action.payload.data,
      };
    }

    case actions.FETCH_EXAM_SUCCESS: {
      return {
        ...state,
        listExams: {
          meta: action.payload.meta,
          pageController: action.payload.data.length,
          data:
            action.payload.meta.current_page > 1
              ? [...state.listExams.data, ...action.payload.data]
              : action.payload.data,
        },
      };
    }

    default:
      return state;
  }
}
