import { Options } from "../types";
import { payloadInsertContact, payloadInsertLead } from "./types";

const actions = {
  FETCH_LIST_LEADS_REQUEST: "FETCH_LIST_LEADS_REQUEST",
  FETCH_LIST_LEADS_SUCCESS: "FETCH_LIST_LEADS_SUCCESS",
  INSERT_LEAD_REQUEST: "INSERT_LEAD_REQUEST",
  INSERT_LEAD_SUCCESS: "INSERT_LEAD_SUCCESS",
  LOSE_LEAD_REQUEST: "LOSE_LEAD_REQUEST",
  LOSE_LEAD_SUCCESS: "LOSE_LEAD_SUCCESS",
  FETCH_LEAD_REQUEST: "FETCH_LEAD_REQUEST",
  FETCH_LEAD_SUCCESS: "FETCH_LEAD_SUCCESS",
  DELETE_LEAD_REQUEST: "DELETE_LEAD_REQUEST",
  DELETE_LEAD_SUCCESS: "DELETE_LEAD_SUCCESS",
  INSERT_LEAD_CONTACT_REQUEST: " INSERT_LEAD_CONTACT_REQUEST",
  INSERT_LEAD_CONTACT_SUCCESS: " INSERT_LEAD_CONTACT_SUCCESS",
  FETCH_LEAD_CONTACT_REQUEST: "FETCH_LEAD_CONTACT_REQUEST",
  FETCH_LEAD_CONTACT_SUCCESS: "FETCH_LEAD_CONTACT_SUCCESS",

  fetchListLeads: (payload: Options, params: Options) => ({
    type: actions.FETCH_LIST_LEADS_REQUEST,
    payload,
    params,
  }),

  insertLead: (payload: payloadInsertLead, callback: () => void) => ({
    type: actions.INSERT_LEAD_REQUEST,
    payload,
    callback,
  }),

  LoseLead: (payload: string, leadID: number, callback: () => void) => ({
    type: actions.LOSE_LEAD_REQUEST,
    payload,
    leadID,
    callback,
  }),

  fetchLead: (leadID: number) => ({ type: actions.FETCH_LEAD_REQUEST, leadID }),

  fetchContactsLead: (leadID: number) => ({
    type: actions.FETCH_LEAD_CONTACT_REQUEST,
    leadID,
  }),

  insertContactLead: (payload: payloadInsertContact, callback: () => void) => ({
    type: actions.INSERT_LEAD_CONTACT_REQUEST,
    payload,
    callback,
  }),

  deleteLead: (leadID: number, params: Options, callback: () => void) => ({
    type: actions.DELETE_LEAD_REQUEST,
    payload: leadID,
    params,
    callback,
  }),
};

export default actions;
