import { all, call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../helpers/api';
import appActions from '../app/actions';
import { actionFetchListEmployeeRequest } from '../registrations/types';
import { Meta } from '../types';
import actions from './actions';
//TYPES
import {
  actionDeleteContractRequest,
  actionDeleteUserRequest,
  actionfetchListContractsRequest,
  actionInactiveUserRequest,
  actionInactiveUserSuccess,
  actionInsertEmployeeSuccess,
  actionListagemAlunosRequest,
  SUCESSS,
} from './types';

export const getListEmployees = (state: any) => state.ConfigReducer.listEmployees;
export const getListProviders = (state: any) => state.ConfigReducer.listProviders;

export function* fetchPageDataRequest() {
  yield takeEvery(actions.PAGE_DATA_REQUEST, function* ({}) {
    try {
      const result: {} = yield call(api.fetchPageData);
      yield put({
        type: actions.PAGE_DATA_SUCCESS,
        payload: result,
      });
    } catch (error) {
      yield put({
        type: appActions.HANDLE_ERRORS,
        payload: error,
      });
    }
  });
}

export function* fetchCondicaoPagamentoRequest() {
  yield takeEvery(
    actions.FETCH_CONDICAO_PAGAMENTO_REQUEST,
    function* ({ loadingFn }: any) {
      try {
        loadingFn?.(true);
        const result: {} = yield call(api.fetchPaymentConditions);

        yield put({
          type: actions.FETCH_CONDICAO_PAGAMENTO_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      } finally {
        loadingFn?.(false);
      }
    }
  );
}

export function* getListEmployeesRequest() {
  yield takeEvery(
    actions.FETCH_LIST_EMPLOYEES_REGISTER_REQUEST,
    function* ({ payload }: actionFetchListEmployeeRequest) {
      try {
        const result: { meta: Meta; data: any } = yield call(
          api.fetchEmployees,
          payload
        );

        yield put({
          type: actions.FETCH_LIST_EMPLOYEES_REGISTER_SUCCESS,
          payload: result,
        });

        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* fetchListContractsRequest() {
  yield takeEvery(
    actions.FETCH_LIST_CONTRACTS_REQUEST,
    function* ({ payload }: actionfetchListContractsRequest) {
      try {
        const result: { meta: Meta; data: any } = yield call(
          api.fetchListContracts,
          payload
        );

        yield put({
          type: actions.FETCH_LIST_CONTRACTS_SUCCESS,
          payload: result,
        });

        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* fetchContractRequest() {
  yield takeEvery(actions.FETCH_CONTRACT_REQUEST, function* ({ payload }: any) {
    try {
      yield put({ type: appActions.IS_LOADING, payload: true });

      const result: {} = yield call(api.getContract, payload);
      yield put({
        type: actions.FETCH_CONTRACT_SUCCESS,
        payload: result,
      });
      yield put({ type: appActions.IS_LOADING, payload: false });
    } catch (error) {
      yield put({
        type: appActions.HANDLE_ERRORS,
        payload: error,
      });
    }
  });
}

export function* fetchPrintRequest() {
  yield takeEvery(actions.FETCH_PRINT_REQUEST, function* ({ payload }: any) {
    try {
      yield put({ type: appActions.IS_LOADING, payload: true });

      const result: {} = yield call(api.getPrint, payload);
      yield put({
        type: actions.FETCH_PRINT_SUCCESS,
        payload: result,
      });
      yield put({ type: appActions.IS_LOADING, payload: false });
    } catch (error) {
      yield put({
        type: appActions.HANDLE_ERRORS,
        payload: error,
      });
    }
  });
}

export function* fetchListPrintsRequest() {
  yield takeEvery(
    actions.FETCH_LIST_PRINTS_REQUEST,
    function* ({ payload }: actionfetchListContractsRequest) {
      try {
        const result: { meta: Meta; data: any } = yield call(
          api.fetchListPrints,
          payload
        );

        yield put({
          type: actions.FETCH_LIST_PRINTS_SUCCESS,
          payload: result,
        });

        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* getListProvidersRequest() {
  yield takeEvery(
    actions.FETCH_LIST_PROVIDERS_REQUEST,
    function* ({ payload }: actionListagemAlunosRequest) {
      try {
        const result: { meta: Meta; data: any } = yield call(
          api.fetchListEmployees,
          payload
        );
        yield put({
          type: actions.FETCH_LIST_PROVIDERS_SUCCESS,
          payload: result,
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* inactiveEmployeeRequest() {
  yield takeEvery(
    actions.INACTIVE_EMPLOYEE_REQUEST,
    function* ({ payload, status, params, callback }: actionInactiveUserRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.inactiveEmployee, payload, status);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.INACTIVE_EMPLOYEE_SUCCESS,
          params: params,
        });
        callback();
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deleteEmployeeRequest() {
  yield takeEvery(
    actions.DELETE_EMPLOYEE_REQUEST,
    function* ({ payload, params, callback }: actionDeleteUserRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: { meta: Meta; data: any } = yield call(
          api.deleteEmployee,
          payload
        );
        yield put({
          type: actions.DELETE_EMPLOYEE_SUCCESS,
          payload: result,
          params: params,
        });
        if (callback && typeof callback == 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deleteContractRequest() {
  yield takeEvery(
    actions.DELETE_CONTRACT_REQUEST,
    function* ({ payload, params, callback }: actionDeleteContractRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.deleteContract, payload);
        yield put({
          type: actions.DELETE_CONTRACT_SUCCESS,
          payload: result,
          params: params,
        });
        callback();
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deletePrintRequest() {
  yield takeEvery(
    actions.DELETE_PRINT_REQUEST,
    function* ({ payload, params, callback }: actionDeleteContractRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.deletePrint, payload);
        yield put({
          type: actions.DELETE_PRINT_SUCCESS,
          payload: result,
          params: params,
        });
        callback();
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertEmployeeRequest() {
  yield takeEvery(
    actions.INSERT_EMPLOYEE_REQUEST,
    function* ({ payload, callback }: actionInsertEmployeeSuccess) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.insertEmployee, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.INSERT_EMPLOYEE_SUCCESS,
          payload: result,
        });
        callback(result);
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertEmployeeSuccess() {
  yield takeEvery(
    actions.INSERT_EMPLOYEE_SUCCESS,
    function* ({ payload }: actionInsertEmployeeSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          message: payload?.messages?.[0] || 'Colaborador adicionado com sucesso!',
        },
      });
    }
  );
}

export function* updateEmployeeRequest() {
  yield takeEvery(
    actions.UPDATE_EMPLOYEE_REQUEST,
    function* ({ payload, id, callback }: actionInsertEmployeeSuccess) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.updateEmployee, payload, id);
        yield put({ type: appActions.IS_LOADING, payload: false });
        callback(result);
        yield put({
          type: actions.UPDATE_EMPLOYEE_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* updateEmployeeSuccess() {
  yield takeEvery(
    actions.UPDATE_EMPLOYEE_SUCCESS,
    function* ({ payload }: actionInsertEmployeeSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          message: payload?.messages?.[0] || 'Colaborador atualizado com sucesso!',
        },
      });
    }
  );
}

export function* deleteEmployeeSuccess() {
  yield takeEvery(
    actions.DELETE_EMPLOYEE_SUCCESS,
    function* ({ payload, params }: SUCESSS) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Colaborador excluído com sucesso!' },
      });
      yield put({
        type: actions.FETCH_LIST_EMPLOYEES_REGISTER_REQUEST,
        payload: params,
      });
    }
  );
}

export function* deleteContractSuccess() {
  yield takeEvery(
    actions.DELETE_CONTRACT_SUCCESS,
    function* ({ payload, params }: SUCESSS) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true },
      });
      yield put({
        type: actions.FETCH_LIST_CONTRACTS_REQUEST,
        payload: params,
      });
    }
  );
}

export function* deletePrintSuccess() {
  yield takeEvery(
    actions.DELETE_PRINT_SUCCESS,
    function* ({ payload, params }: SUCESSS) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true },
      });
      yield put({
        type: actions.FETCH_LIST_PRINTS_REQUEST,
        payload: params,
      });
    }
  );
}

export function* inactiveEmployeeSuccess() {
  yield takeEvery(
    actions.INACTIVE_EMPLOYEE_SUCCESS,
    function* ({ params }: actionInactiveUserSuccess) {
      yield put({
        type: actions.FETCH_LIST_EMPLOYEES_REGISTER_REQUEST,
        payload: params,
      });
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Colaborador inativado com sucesso!' },
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchPageDataRequest),
    spawn(fetchCondicaoPagamentoRequest),
    spawn(fetchListContractsRequest),
    spawn(fetchListPrintsRequest),
    spawn(deleteContractRequest),
    spawn(deleteEmployeeRequest),
    spawn(inactiveEmployeeRequest),
    spawn(fetchContractRequest),
    spawn(fetchPrintRequest),
    spawn(deletePrintRequest),
    spawn(getListProvidersRequest),
    spawn(updateEmployeeRequest),
    spawn(insertEmployeeRequest),
    spawn(getListEmployeesRequest),
    fork(insertEmployeeSuccess),
    fork(updateEmployeeSuccess),
    fork(inactiveEmployeeSuccess),
    fork(deleteEmployeeSuccess),
    fork(deletePrintSuccess),
    fork(deleteContractSuccess),
  ]);
}
