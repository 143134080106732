import { Options } from '../types';

const actions = {
  FETCH_SCHEDULE_REQUEST: 'FETCH_SCHEDULE_REQUEST',
  FETCH_SCHEDULE_SUCCESS: 'FETCH_SCHEDULE_SUCCESS',
  FETCH_EXAM_REQUEST: 'FETCH_EXAM_REQUEST',
  FETCH_EXAM_SUCCESS: 'FETCH_EXAM_SUCCESS',
  INSERT_SCHEDULE_REQUEST: 'INSERT_SCHEDULE_REQUEST',
  INSERT_SCHEDULE_SUCCESS: 'INSERT_SCHEDULE_SUCCESS',
  UPDATE_SCHEDULE_REQUEST: 'UPDATE_SCHEDULE_REQUEST',
  UPDATE_SCHEDULE_SUCCESS: 'UPDATE_SCHEDULE_SUCCESS',
  DELETE_SCHEDULE_REQUEST: 'DELETE_SCHEDULE_REQUEST',
  DELETE_SCHEDULE_SUCCESS: 'DELETE_SCHEDULE_SUCCESS',

  fetchPracticeSchedule: () => ({
    type: actions.FETCH_SCHEDULE_REQUEST,
  }),

  insertPracticeSchedule: (
    payload: any,
    callback: () => void
  ) => ({
    type: actions.INSERT_SCHEDULE_REQUEST,
    payload,
    callback,
  }),

  updatePracticeSchedule: (
    payload: any,
    callback: () => void
  ) => ({
    type: actions.UPDATE_SCHEDULE_REQUEST,
    payload,
    callback,
  }),

  deletePracticeSchedule: (
    CardID: number,
    callback: () => void
  ) => ({
    type: actions.DELETE_SCHEDULE_REQUEST,
    payload: CardID,
    callback,
  }),

  fetchExam: (payload: Options) => ({
    type: actions.FETCH_EXAM_REQUEST,
    payload,
  }),
};

export default actions;
