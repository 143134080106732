import { Options } from '../types';

const actions = {
  INSERT_VENDA_REQUEST: 'INSERT_VENDA_REQUEST',
  INSERT_VENDA_SUCCESS: 'INSERT_VENDA_SUCCESS',
  INSERT_SERVICE_SALE_REQUEST: 'INSERT_SERVICE_SALE_REQUEST',
  INSERT_SERVICE_SALE_SUCCESS: 'INSERT_SERVICE_SALE_SUCCESS',
  APROVAR_VENDA_REQUEST: 'APROVAR_VENDA_REQUEST',
  APROVAR_VENDA_SUCCESS: 'APROVAR_VENDA_SUCCESS',
  FETCH_LIST_VENDAS_REQUEST: 'FETCH_LIST_VENDAS_REQUEST',
  FETCH_LIST_VENDAS_SUCCESS: 'FETCH_LIST_VENDAS_SUCCESS',
  FETCH_LIST_VENDA_RAPIDA_REQUEST: 'FETCH_LIST_VENDA_RAPIDA_REQUEST',
  FETCH_LIST_VENDA_RAPIDA_SUCCESS: 'FETCH_LIST_VENDA_RAPIDA_SUCCESS',
  FETCH_VENDA_SUCCESS: 'FETCH_VENDA_SUCCESS',
  FETCH_VENDA_REQUEST: 'FETCH_VENDA_REQUEST',
  IMPRIMIR_CONTRATO_REQUEST: 'IMPRIMIR_CONTRATO_REQUEST',

  insertVenda: (venda: any, callback: () => void) => ({
    type: actions.INSERT_VENDA_REQUEST,
    payload: venda,
    callback,
  }),

  insertSaleService: (venda: any, callback: () => void) => ({
    type: actions.INSERT_SERVICE_SALE_REQUEST,
    payload: venda,
    callback,
  }),

  aprovarVenda: (venda: any, callback: () => void) => ({
    type: actions.APROVAR_VENDA_REQUEST,
    payload: venda,
    callback,
  }),

  fetchListVendas: (payload: Options, token: string) => ({
    type: actions.FETCH_LIST_VENDAS_REQUEST,
    payload,
    token,
  }),

  FetchListVendaRapida: (payload: Options, token: string) => ({
    type: actions.FETCH_LIST_VENDA_RAPIDA_REQUEST,
    payload,
    token,
  }),

  imprimirContrato: (codigoVenda: number, callback: () => void) => ({
    type: actions.IMPRIMIR_CONTRATO_REQUEST,
    codigoVenda,
    callback,
  }),
};

export default actions;
