import { all, takeEvery, put, call, fork, spawn, select } from 'redux-saga/effects';

import appActions from '../app/actions';
import actions from './actions';
import api from '../../helpers/api';

import {
  actionDeleteVehicleRequest,
  actionFetchListVehiclesRequest,
  actionFetchVehicleRequest,
  actionInsertVehicleRequest,
} from './types';
import { actionSuccess } from '../leads/types';
import { Meta } from '../types';

export function* getListagemVehiclesRequest() {
  yield takeEvery(
    actions.FETCH_LIST_VEHICLES_REQUEST,
    function* ({ payload }: actionFetchListVehiclesRequest) {
      try {
        const result: { meta: Meta; data: any } = yield call(
          api.fetchListVehicles,
          payload
        );

        yield put({
          type: actions.FETCH_LIST_VEHICLES_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* fetchVehicleRequest() {
  yield takeEvery(
    actions.FETCH_VEHICLE_REQUEST,
    function* ({ payload }: actionFetchVehicleRequest) {
      try {
        const result: any = yield call(api.fetchVehicle, payload);
        const aux = {
          ...result.data,
          categoria: {
            key: result.data.categoria,
            label: result.data.categoria,
            value: result.data.categoria,
          },
        };

        yield put({
          type: actions.FETCH_VEHICLE_SUCCESS,
          payload: aux,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertVehicleRequest() {
  yield takeEvery(
    actions.INSERT_VEHICLE_REQUEST,
    function* ({ payload, callback }: actionInsertVehicleRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: { data: any } = yield call(api.insertVehicle, payload);

        yield put({
          type: actions.INSERT_VEHICLE_SUCCESS,
          payload: result,
        });
        if (typeof callback == 'function') {
          callback(result?.data);
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* updateVehicleRequest() {
  yield takeEvery(
    actions.UPDATE_VEHICLE_REQUEST,
    function* ({ payload, callback }: actionInsertVehicleRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: any = yield call(api.updateVehicle, payload);

        yield put({
          type: actions.UPDATE_VEHICLE_SUCCESS,
          payload: result,
        });
        if (typeof callback == 'function') {
          callback(result?.data);
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deleteVehicleRequest() {
  yield takeEvery(
    actions.DELETE_VEHICLE_REQUEST,
    function* ({ payload, params, callback }: actionDeleteVehicleRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.deleteVehicle, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.DELETE_VEHICLE_SUCCESS,
          payload: params,
        });
        callback();
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* inactiveVehicleRequest() {
  yield takeEvery(
    actions.INACTIVE_VEHICLE_REQUEST,
    function* ({ payload, status, params, callback }: actionDeleteVehicleRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.inactiveVehicle, payload, status);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.DELETE_VEHICLE_SUCCESS,
          payload: params,
        });

        if (typeof callback == 'function') {
          callback();
        }
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertVehicleSuccess() {
  yield takeEvery(actions.INSERT_VEHICLE_SUCCESS, function* ({}: actionSuccess) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Sucesso' },
    });
  });
}

export function* updateVehicleSuccess() {
  yield takeEvery(actions.INSERT_VEHICLE_SUCCESS, function* ({}: actionSuccess) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Sucesso' },
    });
  });
}

export function* deleteVehicledSuccess() {
  yield takeEvery(
    actions.DELETE_VEHICLE_SUCCESS,
    function* ({ payload }: actionSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Sucesso' },
      });
      yield put({
        type: actions.FETCH_LIST_VEHICLES_REQUEST,
        payload: payload,
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(getListagemVehiclesRequest),
    spawn(fetchVehicleRequest),
    spawn(insertVehicleRequest),
    spawn(updateVehicleRequest),
    spawn(inactiveVehicleRequest),
    spawn(deleteVehicleRequest),
    fork(insertVehicleSuccess),
    fork(deleteVehicledSuccess),
    fork(updateVehicleSuccess),
  ]);
}
