const actions = {
  FETCH_CONFIG_SCHEDULE_REQUEST: 'FETCH_CONFIG_SCHEDULE_REQUEST',
  FETCH_CONFIG_SCHEDULE_SUCCESS: 'FETCH_CONFIG_SCHEDULE_SUCCESS',

  fetchConfigSchedule: () => ({
    type: actions.FETCH_CONFIG_SCHEDULE_REQUEST,
  }),
};

export default actions;
