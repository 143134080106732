import actions from './actions';

const initState = {
  cookieName: 'id_token',
  idToken: null,
  profile: {},
  primeiroAcc: false,

  empresas: {
    '0': {
      emp: 0,
      nome: ' ',
      cpf_cnpj: 0,
    },
  },
};

export default function authReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.SET_COOKIE_NAME: {
      return { ...state, cookieName: action.payload };
    }

    case actions.SET_PRIMEIRO_ACESSO: {
      return { ...state, primeiroAcc: action.payload };
    }

    case actions.FETCH_TOKEN_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          idToken: action.payload.idToken,
          profile: action.payload.profile,
        };
      } else {
        return { ...state };
      }

    case actions.FETCH_COMPANIES_SUCCESS: {
      return {
        ...state,
        empresas: action.payload,
      };
    }

    case actions.LOGOUT_SUCCESS:
      return initState;

    default:
      return state;
  }
}
