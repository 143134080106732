import actions from './actions';

const initialState = {
  schedule: {
    cancelar_aulas_antecedencia: null,
    marcar_exame_antecedencia: null,
  },
};

export default function ConfigScheduleReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case actions.FETCH_CONFIG_SCHEDULE_SUCCESS: {
      return {
        schedule: action.payload.data,
      };
    }

    default:
      return state;
  }
}
