import actions from './actions';

const initState = {
  employee: null,
  process: null,

  listProccess: {
    data: [],
    pageController: 0,
    meta: {},
  },
};

export default function registrationsReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.SET_PROCCESS: {
      return {
        ...state,
        process: action.payload,
      };
    }

    case actions.FETCH_LIST_PROCCESS_SUCCESS: {
      return {
        ...state,
        listProccess: {
          data:
            action.payload.meta.current_page > 1
              ? [...state.listProccess.data, ...action.payload.data]
              : action.payload.data,
          pageController: action.payload.data.length,
          meta: action.payload.meta,
        },
      };
    }

    default:
      return state;
  }
}
