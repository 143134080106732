import actions from './actions';

const initialState = {
  listServices: {
    data: [],
    meta: [],
  },
  service: null,
  serviceID: null,
};

export default function ServicesReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_LIST_SERVICES_SUCCESS: {
      return {
        ...state,
        listServices: {
          data: action.payload.data,
          meta: action.payload.meta,
        },
        service: null,
        serviceID: null,
      };
    }

    case actions.SET_SERVICE: {
      return {
        ...state,
        serviceID: action.payload,
      };
    }

    case actions.FETCH_SERVICE_SUCCESS: {
      return {
        ...state,
        service: action.payload.data,
      };
    }

    default:
      return state;
  }
}
