import actions from './actions';
import { Permission } from './types';

const initialState: Permission = {
  //@ts-ignore
  permission: {},
};

export default function PermissionReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case actions.FETCH_PERMISSIONS_SUCCESS: {
      return {
        permission: action.payload.data,
      };
    }

    default:
      return state;
  }
}
