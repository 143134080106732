import Router from 'next/router';
import { all, call, fork, put, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../helpers/api';
import alunosActions from '../alunos/actions';
import appActions from '../app/actions';
import { ResponseGenerator } from '../types';
import actions from './actions';

export const alunoID = (state: any) => state.AlunosReducer.alunoID;

export function* insertVendaRequest() {
  yield takeEvery(
    actions.INSERT_VENDA_REQUEST,
    function* ({ payload, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: ResponseGenerator = yield call(api.insertVenda, payload);
        yield put({
          type: actions.INSERT_VENDA_SUCCESS,
          payload: result,
        });
        if (typeof callback == 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertSaleServiceRequest() {
  yield takeEvery(
    actions.INSERT_SERVICE_SALE_REQUEST,
    function* ({ payload, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: ResponseGenerator = yield call(api.insertSaleService, payload);
        yield put({
          type: actions.INSERT_SERVICE_SALE_SUCCESS,
          payload: result,
        });
        if (typeof callback == 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* aprovarVendaRequest() {
  yield takeEvery(
    actions.APROVAR_VENDA_REQUEST,
    function* ({ payload, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: ResponseGenerator = yield call(api.aprovarVenda, payload);
        yield put({
          type: actions.APROVAR_VENDA_SUCCESS,
          payload: result,
        });
        if (result.data.pessoa) {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* getListagemVendaRequest() {
  yield takeEvery(actions.FETCH_LIST_VENDAS_REQUEST, function* ({ payload }: any) {
    try {
      const result: ResponseGenerator = yield call(api.fetchListVendas, payload);

      yield put({
        type: actions.FETCH_LIST_VENDAS_SUCCESS,
        payload: result,
      });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* getListagemVendaRapidaRequest() {
  yield takeEvery(
    actions.FETCH_LIST_VENDA_RAPIDA_REQUEST,
    function* ({ payload }: any) {
      try {
        const result: ResponseGenerator = yield call(
          api.fetchListVendaRapida,
          payload
        );
        yield put({
          type: actions.FETCH_LIST_VENDA_RAPIDA_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertVendaSuccess() {
  yield takeEvery(actions.INSERT_VENDA_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        message: 'Sua venda acaba de ser gerada.',
        title: 'Nova venda',
      },
    });
    yield put({
      type: alunosActions.FETCH_LIST_FINANCEIRO_ALUNO_REQUEST,
      alunoID: payload.data.pessoa,
    });
    Router.push({
      pathname: '/alunos/perfil',
      query: { codigo: payload.data.codigo },
    });
  });
}
export function* insertSaleServiceSuccess() {
  yield takeEvery(actions.INSERT_SERVICE_SALE_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        message: 'Sua venda acaba de ser gerada.',
        title: 'Nova venda de serviço',
      },
    });

    yield put({
      type: alunosActions.FETCH_LIST_FINANCEIRO_ALUNO_REQUEST,
      alunoID: payload.data.pessoa,
    });

    yield put({
      type: alunosActions.FETCH_ALUNO_REQUEST,
      payload: payload.data.pessoa,
    });
  });
}

export function* aprovarVendaSuccess() {
  yield takeEvery(actions.APROVAR_VENDA_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        message: 'Sua venda acaba de ser gerada.',
        title: 'Nova venda',
      },
    });
    yield put({
      type: alunosActions.FETCH_LIST_FINANCEIRO_ALUNO_REQUEST,
      alunoID: payload.data.pessoa,
    });
    Router.push({
      pathname: '/alunos/perfil',
      query: { codigo: payload.data.codigo },
    });
  });
}

export default function* rootSaga() {
  yield all([
    spawn(insertVendaRequest),
    spawn(aprovarVendaRequest),
    spawn(insertSaleServiceRequest),
    spawn(getListagemVendaRapidaRequest),
    spawn(getListagemVendaRequest),
    fork(insertVendaSuccess),
    fork(insertSaleServiceSuccess),
    fork(aprovarVendaSuccess),
  ]);
}
