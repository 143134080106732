import actions from './actions';

const initialState = {
  listStatement: {
    data: [],
    meta: {},
    extra: {},
    pageController: 0,
  },
};

export default function StatementReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_LIST_STATEMENT_SUCCESS: {
      return {
        ...state,
        listStatement: {
          meta: action.payload.meta,
          extra: action.payload.extra,
          pageController: action.payload.data.length,
          data:
            action.payload.meta.current_page > 1
              ? [...state.listStatement.data, ...action.payload.data]
              : action.payload.data,
        },
      };
    }

    default:
      return state;
  }
}
