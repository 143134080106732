import { Options } from "../types";

const actions = {
  FETCH_LIST_SERVICES_REQUEST: "FETCH_LIST_SERVICES_REQUEST",
  FETCH_LIST_SERVICES_SUCCESS: "FETCH_LIST_SERVICES_SUCCESS",
  FETCH_SERVICE_REQUEST: "FETCH_SERVICE_REQUEST",
  FETCH_SERVICE_SUCCESS: "FETCH_SERVICE_SUCCESS",
  INSERT_SERVICE_REQUEST: "INSERT_SERVICE_REQUEST",
  INSERT_SERVICE_SUCCESS: "INSERT_SERVICE_SUCCESS",
  UPDATE_SERVICE_REQUEST: "UPDATE_SERVICE_REQUEST",
  UPDATE_SERVICE_SUCCESS: "UPDATE_SERVICE_SUCCESS",
  INACTIVE_SERVICE_REQUEST: "INACTIVE_SERVICE_REQUEST",
  INACTIVE_SERVICE_SUCCESS: "INACTIVE_SERVICE_SUCCESS",
  DELETE_SERVICE_REQUEST: "DELETE_SERVICE_REQUEST",
  DELETE_SERVICE_SUCCESS: "DELETE_SERVICE_SUCCESS",
  SET_SERVICE: "SET_SERVICE",

  fetchListServices: (payload: Options) => ({
    type: actions.FETCH_LIST_SERVICES_REQUEST,
    payload,
  }),

  fetchService: (serviceID: number) => ({
    type: actions.FETCH_SERVICE_REQUEST,
    payload: serviceID,
  }),

  insertService: (payload: any, callback: () => void) => ({
    type: actions.INSERT_SERVICE_REQUEST,
    payload,
    callback,
  }),

  updateService: (payload: any, callback: () => void) => ({
    type: actions.UPDATE_SERVICE_REQUEST,
    payload,
    callback,
  }),

  inactiveService: (
    serviceID: number,
    status: number,
    params: Options,
    callback: () => void
  ) => ({
    type: actions.INACTIVE_SERVICE_REQUEST,
    payload: serviceID,
    status,
    params,
    callback,
  }),

  deleteService: (
    serviceID: number,
    params: Options,
    callback: () => void
  ) => ({
    type: actions.DELETE_SERVICE_REQUEST,
    payload: serviceID,
    params,
    callback,
  }),

  setService: (serviceID: number) => ({
    type: actions.SET_SERVICE,
    payload: serviceID,
  }),
};

export default actions;
