import actions from './actions';

const initState = {
  drawer: false,
  novidade: false,
  loading: false,
  snackbar: {
    activated: false,
    time: 5000,
    message: '',
    variant: 'success',
  },
  dashboard: {
    contratado: 0,
    days: null,
    plan_name: null,
  },
  extension: false,
  nps: false,
  config: {
    bloqueado: null,
  },
  news: [],
  newsRequest: false,
  recursos: [],
  cobrancasList: null,
};
export default function appReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.TOGGLE_SNACKBAR:
      if (!action.payload.time) {
        action.payload.time = 5000;
      }

      if (action.payload.message) {
        action.payload.time = (action.payload?.message || '')?.length * 100;
      }

      return { ...state, snackbar: action.payload };

    case actions.TOGGLE_DRAWER:
      return { ...state, drawer: action.payload };

    case actions.TOGGLE_NEWS:
      return {
        ...state,
        novidade: action.payload,
      };

    case actions.SET_EXTENSION:
      return { ...state, extension: action.payload };

    case actions.SET_NPS_SUCCESS:
      return { ...state, nps: action.payload?.data?.mostrar_nps };

    case actions.SET_NPS:
      return { ...state, nps: action.payload };

    case actions.IS_LOADING:
      return { ...state, loading: action.payload };

    case actions.GET_CONFIG_SUCCESS:
      return { ...state, config: { ...action.payload } };

    case actions.GET_RESOURCES_SUCCESS:
      return { ...state, recursos: action.payload.recursos };

    case actions.GET_CONTRACT_DAY_SUCCESS:
      return {
        ...state,
        dashboard: {
          days: action.payload.dias_teste_gratis,
          plan_name: action.payload.plano,
        },
      };

    case actions.FETCH_COBRANCAS_SUCCESS:
      return {
        ...state,
        cobrancasList: {
          data: action.payload.data,
          request: true,
        },
      };

    case actions.GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
        newsRequest: true,
      };

    case actions.CONTRACT_UNDERSTAND: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          contratado: 0,
          days: null,
        },
      };
    }

    case actions.CONTRACT_BUTTON_SUCCESS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          contratado: 1,
          days: null,
        },
      };

    case 'LOGOUT_SUCCESS':
      return initState;

    default:
      return state;
  }
}
