import { all, call, put, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../helpers/api';
import appActions from '../app/actions';
import actions from './actions';
import { actionFetchListPermissionsRequest } from './types';

export function* fetchListPermissions() {
  yield takeEvery(
    actions.FETCH_PERMISSIONS_REQUEST,
    function* ({}: actionFetchListPermissionsRequest) {
      try {
        const result: { data: any } = yield call(
          api.fetchListPermissions
        );

        yield put({
          type: actions.FETCH_PERMISSIONS_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export default function* rootSaga() {
  yield all([spawn(fetchListPermissions)]);
}
