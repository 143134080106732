import { Options } from '../types';

const actions = {
  FETCH_LIST_STATEMENT_REQUEST: 'FETCH_LIST_STATEMENT_REQUEST',
  FETCH_LIST_STATEMENT_SUCCESS: 'FETCH_LIST_STATEMENT_SUCCESS',
  INSERT_EXPENSE_REQUEST: 'INSERT_EXPENSE_REQUEST',
  INSERT_EXPENSE_SUCCESS: 'INSERT_EXPENSE_SUCCESS',
  DELETE_EXPENSE_REQUEST: 'DELETE_EXPENSE_REQUEST',
  DELETE_EXPENSE_SUCCESS: 'DELETE_EXPENSE_SUCCESS',

  fetchListStatement: (payload: Options) => ({
    type: actions.FETCH_LIST_STATEMENT_REQUEST,
    payload,
  }),

  insertExpense: (payload: any, values: any, callback: () => void) => ({
    type: actions.INSERT_EXPENSE_REQUEST,
    payload,
    values,
    callback,
  }),

  deleteExpense: (expenseID: number, params: Options, callback: () => void) => ({
    type: actions.DELETE_EXPENSE_REQUEST,
    payload: expenseID,
    params,
    callback,
  }),
};

export default actions;
