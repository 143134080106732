import moment from 'moment';
import actions from './actions';

const initialState = {
  listPeriods: {
    data: [],
    pageController: 0,
    meta: { current_page: 0 },
  },
  period: null,
  periodID: null,
  filters: {
    page: 1,
    tipo: -1,
    data: 1,
    'data-inicial': moment().startOf('month').format('YYYY-MM-DD'),
    'data-final': moment().endOf('month').format('YYYY-MM-DD'),
  },
};

export default function PeriodsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_LIST_PERIODS_SUCCESS: {
      return {
        ...state,
        listPeriods: {
          meta: action.payload.meta,
          pageController: action.payload.data.length,
          data:
            action.payload.meta.current_page > 1
              ? [...state.listPeriods.data, ...action.payload.data]
              : action.payload.data,
        },
        period: null,
        periodID: null,
      };
    }

    case actions.SET_PERIOD: {
      return {
        ...state,
        periodID: action.payload,
      };
    }

    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }

    case actions.FETCH_PERIOD_SUCCESS: {
      return {
        ...state,
        period: action.payload.data,
      };
    }

    default:
      return state;
  }
}
