import {
  all,
  takeEvery,
  put,
  call,
  fork,
  spawn,
  select,
} from 'redux-saga/effects';

import appActions from '../app/actions';
import actions from './actions';
import api from '../../helpers/api';

import {
  actionDeleteExpenseRequest,
  actionFetchListFinancialRequest,
  actionInsertExpenseRequest,
} from './types';
import { actionSuccess } from '../leads/types';
import { Meta } from '../types';

export const getListStatement = (state: any) =>
  state.StatementReducer.listStatement;

export function* fetchStatementList() {
  yield takeEvery(
    actions.FETCH_LIST_STATEMENT_REQUEST,
    function* ({ payload }: actionFetchListFinancialRequest) {
      try {
        const result: { meta: Meta; data: any } = yield call(
          api.fetchListStatement,
          payload
        );
        yield put({
          type: actions.FETCH_LIST_STATEMENT_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertExpenseRequest() {
  yield takeEvery(
    actions.INSERT_EXPENSE_REQUEST,
    function* ({
      payload,
      values,
      callback,
    }: actionInsertExpenseRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: any = yield call(api.insertExpense, payload);

        yield put({
          type: actions.INSERT_EXPENSE_SUCCESS,
          payload: result,
          values: values,
        });
        if (typeof callback == 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deleteExpenseRequest() {
  yield takeEvery(
    actions.DELETE_EXPENSE_REQUEST,
    function* ({
      payload,
      params,
      callback,
    }: actionDeleteExpenseRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.deleteExpense, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.DELETE_EXPENSE_SUCCESS,
          payload: params,
        });
        callback();
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deleteExpensedSuccess() {
  yield takeEvery(
    actions.DELETE_EXPENSE_SUCCESS,
    function* ({ payload }: actionSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Sucesso' },
      });
      yield put({
        type: actions.FETCH_LIST_STATEMENT_REQUEST,
        payload: payload,
      });
    }
  );
}

export function* insertExpenseSuccess() {
  yield takeEvery(
    actions.INSERT_EXPENSE_SUCCESS,
    function* ({ values }: actionSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Sucesso' },
      });
      yield put({
        type: actions.FETCH_LIST_STATEMENT_REQUEST,
        payload: { page: 1, ...values },
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchStatementList),
    spawn(insertExpenseRequest),
    spawn(deleteExpenseRequest),
    fork(insertExpenseSuccess),
    fork(deleteExpensedSuccess),
  ]);
}
