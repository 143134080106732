const actions = {
  FETCH_PERMISSIONS_REQUEST: 'FETCH_PERMISSIONS_REQUEST',
  FETCH_PERMISSIONS_SUCCESS: 'FETCH_PERMISSIONS_SUCCESS',

  fetchPermissions: () => ({
    type: actions.FETCH_PERMISSIONS_REQUEST,
  }),
};

export default actions;
