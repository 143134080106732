import Router from 'next/router';
import { all, call, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../helpers/api';
import { removeCookie } from '../../helpers/session';
import { getCookieName } from '../auth/saga';
import { default as actions, default as appActions } from './actions';
import { actionHandleErrors, actionHandleSuccess } from './types';

export function* handleErrors() {
  yield takeEvery(
    appActions.HANDLE_ERRORS,
    function* ({ payload }: actionHandleErrors) {
      if (payload) {
        if (payload.error) {
          yield put({ type: appActions.IS_LOADING, payload: false });
          yield put({
            type: appActions.TOGGLE_SNACKBAR,
            payload: {
              activated: true,
              message: payload.error,
              title: 'Aviso',
              variant: 'danger',
            },
          });
        } else if (payload.erro) {
          yield put({ type: appActions.IS_LOADING, payload: false });
          yield put({
            type: appActions.TOGGLE_SNACKBAR,
            payload: {
              activated: true,
              message: payload.erro?.mensagem,
              title: 'Aviso',
              variant: 'danger',
            },
          });
        } else if (payload.errors) {
          yield put({ type: appActions.IS_LOADING, payload: false });
          const { errors } = payload;
          if (Array.isArray(errors)) {
            const count = errors.length;
            if (!errors.type) {
              if (count === 1) {
                yield put({
                  type: appActions.TOGGLE_SNACKBAR,
                  payload: {
                    activated: true,
                    message: errors[0],
                    title: 'Aviso',
                    variant: 'danger',
                  },
                });
              }
            }
          }
        } else if (payload.authErrors) {
          yield put({ type: appActions.IS_LOADING, payload: null });
          const cookieName: string = yield select(getCookieName);
          yield removeCookie(cookieName);
          return Router.push('/signin');
        }
      }
    }
  );
}

export function* handleSuccess() {
  yield takeEvery(
    appActions.HANDLE_SUCCESS,
    function* ({ payload }: actionHandleSuccess) {
      if (payload && payload.messages) {
        yield put({ type: appActions.IS_LOADING, payload: false });
        if (Array.isArray(payload.messages)) {
          let count = payload.messages.length;
          if (count === 1) {
            yield put({
              type: appActions.TOGGLE_SNACKBAR,
              payload: {
                activated: true,
                title: 'Sucesso',
                message: payload.messages[0],
                variant: 'success',
              },
            });
          }
        }
      }
    }
  );
}

export function* getContractDay() {
  yield takeEvery(appActions.GET_CONTRACT_DAY, function* ({}: any) {
    try {
      const result: { data: any } = yield call(api.fetchContractDay);
      yield put({
        type: actions.GET_CONTRACT_DAY_SUCCESS,
        payload: result.data,
      });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* getNews() {
  yield takeEvery(appActions.GET_NEWS_REQUEST, function* ({}: any) {
    try {
      const result: { data: any } = yield call(api.getNews);
      yield put({
        type: actions.GET_NEWS_SUCCESS,
        payload: result.data,
      });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* getConfigRequest() {
  yield takeEvery(appActions.GET_CONFIG_REQUEST, function* ({}: any) {
    try {
      const result: { data: { bloqueado: boolean } } = yield call(api.fetchConfig);
      yield put({
        type: actions.GET_CONFIG_SUCCESS,
        payload: result.data,
      });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* fetchCobrancasRequest() {
  yield takeEvery(appActions.FETCH_COBRANCAS_REQUEST, function* ({}: any) {
    try {
      const result: { data: any[] } = yield call(api.fetchCobrancas);
      yield put({
        type: actions.FETCH_COBRANCAS_SUCCESS,
        payload: result,
      });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* getResourcesRequest() {
  yield takeEvery(appActions.GET_RESOURCES_REQUEST, function* ({}: any) {
    try {
      const result: { data: { recursos: string[] } } = yield call(
        api.fetchResources
      );

      yield put({
        type: appActions.GET_RESOURCES_SUCCESS,
        payload: result.data,
      });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* getNPS() {
  yield takeEvery(appActions.SET_NPS_REQUEST, function* () {
    try {
      let result: { mostrar: boolean } = yield call(api.getNPS);
      yield put({
        type: appActions.SET_NPS_SUCCESS,
        payload: result,
      });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export default function* rootSaga() {
  yield all([
    spawn(handleErrors),
    spawn(handleSuccess),
    spawn(getResourcesRequest),
    spawn(fetchCobrancasRequest),
    spawn(getConfigRequest),
    spawn(getNews),
    spawn(getNPS),
    spawn(getContractDay),
  ]);
}
