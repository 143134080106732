import {
  all,
  takeEvery,
  put,
  call,
  fork,
  spawn,
  select,
} from 'redux-saga/effects';

import appActions from '../app/actions';
import actions from './actions';
import registrationsAction from '../registrations/actions';
import api from '../../helpers/api';

import {
  actionDeleteServiceRequest,
  actionFetchListServicesRequest,
  actionFetchServiceRequest,
  actionInactiveServiceRequest,
  actionInsertServiceRequest,
} from './types';
import { actionSuccess } from '../leads/types';

export const getListServices = (state: any) =>
  state.ServicesReducer.listServices;

export function* getListagemServicesRequest() {
  yield takeEvery(
    actions.FETCH_LIST_SERVICES_REQUEST,
    function* ({ payload }: actionFetchListServicesRequest) {
      try {
        let list = yield select(getListServices);

        const result: Record<string, any> = yield call(
          api.fetchListServices,
          payload
        );
        yield put({
          type: actions.FETCH_LIST_SERVICES_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchServiceRequest() {
  yield takeEvery(
    actions.FETCH_SERVICE_REQUEST,
    function* ({ payload }: actionFetchServiceRequest) {
      yield put({ type: appActions.IS_LOADING, payload: true });
      try {
        const result: any = yield call(api.fetchService, payload);
        yield put({
          type: actions.FETCH_SERVICE_SUCCESS,
          payload: result,
        });

        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertServiceRequest() {
  yield takeEvery(
    actions.INSERT_SERVICE_REQUEST,
    function* ({ payload, callback }: actionInsertServiceRequest) {
      yield put({ type: appActions.IS_LOADING, payload: true });
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: any = yield call(api.insertService, payload);

        yield put({
          type: actions.INSERT_SERVICE_SUCCESS,
          payload: result,
        });

        yield put({ type: appActions.IS_LOADING, payload: false });
        if (typeof callback == 'function') {
          callback(result);
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* updateServiceRequest() {
  yield takeEvery(
    actions.UPDATE_SERVICE_REQUEST,
    function* ({ payload, callback }: actionInsertServiceRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: Record<string, any> = yield call(
          api.updateService,
          payload
        );

        yield put({
          type: actions.UPDATE_SERVICE_SUCCESS,
          payload: result,
        });
        if (typeof callback == 'function') {
          callback(result);
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* deleteServiceRequest() {
  yield takeEvery(
    actions.DELETE_SERVICE_REQUEST,
    function* ({
      payload,
      params,
      callback,
    }: actionDeleteServiceRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.deleteService, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.DELETE_SERVICE_SUCCESS,
          payload: params,
        });
        callback();
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* inactiveServiceRequest() {
  yield takeEvery(
    actions.INACTIVE_SERVICE_REQUEST,
    function* ({
      payload,
      status,
      params,
      callback,
    }: actionInactiveServiceRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.inactiveService, payload, status);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.DELETE_SERVICE_SUCCESS,
          payload: params,
        });

        if (typeof callback == 'function') {
          callback();
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertServiceSuccess() {
  yield takeEvery(
    actions.INSERT_SERVICE_SUCCESS,
    function* ({}: actionSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Sucesso' },
      });
    }
  );
}

export function* updateServiceSuccess() {
  yield takeEvery(
    actions.UPDATE_SERVICE_SUCCESS,
    function* ({}: actionSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Sucesso' },
      });
    }
  );
}

export function* deleteServicesSuccess() {
  yield takeEvery(
    actions.DELETE_SERVICE_SUCCESS,
    function* ({ payload }: actionSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Sucesso' },
      });
      //TODO TROQUEI AQUI POIS a REGRA É TUDO DENTRO DE PROCESSOS POR ISSO FIZ ISSO
      yield put({
        type: registrationsAction.FETCH_LIST_PROCCESS_REQUEST,
        payload: { ...payload, page: 1 },
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(getListagemServicesRequest),
    spawn(fetchServiceRequest),
    spawn(insertServiceRequest),
    spawn(updateServiceRequest),
    spawn(deleteServiceRequest),
    spawn(inactiveServiceRequest),
    fork(insertServiceSuccess),
    fork(deleteServicesSuccess),
    fork(updateServiceSuccess),
  ]);
}
