import { all, takeEvery, put, call, fork, spawn, select } from 'redux-saga/effects';
import Api from '../../helpers/api';
import actions from './actions';
import appActions from '../app/actions';
import Router from 'next/router';
import jwtDecode from 'jwt-decode';
import { removeCookie, setCookie } from '../../helpers/session';
import redirect from '../../helpers/redirect';
import {
  RedirectWithTokenSuccess,
  FetchTokenRequest,
  LoginRequest,
  ForgotPasswordRequest,
  FecthCompaniesRequest,
  Success,
  Token,
  EmpresasSuccess,
  ActionUpdatePasswordRequest,
} from './types';

export const getCookieName = (state: any) => state.AuthReducer.cookieName;
export const getUserID = (state: any) => state.AuthReducer.profile.sub;

export function* redirectWithToken() {
  yield takeEvery(
    actions.REDIRECT_WITH_TOKEN_REQUEST,
    function* ({ payload, ctx }: RedirectWithTokenSuccess) {
      try {
        const result: any = yield call(Api.verificarToken, payload);
        if (result?.data?.valid) {
          yield put({
            type: actions.REDIRECT_WITH_TOKEN_SUCCESS,
            remember: true,
            payload,
            ctx,
          });
        } else {
          redirect(ctx, '/signin');
        }
      } catch (error) {
        yield put({ type: appActions.IS_LOADING, payload: false });
        redirect(ctx, '/signin');
      }
    }
  );
}

export function* redirectWithTokenSuccess() {
  yield takeEvery(
    actions.REDIRECT_WITH_TOKEN_SUCCESS,
    function* ({ payload, remember, ctx }: RedirectWithTokenSuccess) {
      try {
        if (payload) {
          const decoded: any = jwtDecode(payload);
          if (decoded) {
            yield put({
              type: actions.SET_COOKIE_NAME,
              payload: `id_token`,
            });
            setCookie(`id_token`, payload, remember);

            if (decoded.emp == 0) {
              return redirect(ctx, '/empresa');
            }

            if (decoded.tipo == 0) {
              return redirect(ctx, '/alunos');
            }

            redirect(ctx, '/alunos');
          }
        }
      } catch (error) {
        redirect(ctx, '/signin');
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
      yield put({ type: appActions.IS_LOADING, payload: false });
    }
  );
}

export function* fetchTokenRequest() {
  yield takeEvery(
    actions.FETCH_TOKEN_REQUEST,
    function* ({ payload, ctx }: FetchTokenRequest) {
      const decoded = jwtDecode(payload);
      if (decoded) {
        yield put({
          type: actions.FETCH_TOKEN_SUCCESS,
          payload: { idToken: payload, profile: decoded },
        });
      }
    }
  );
}

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* ({ payload }: LoginRequest) {
    try {
      yield put({ type: appActions.IS_LOADING, payload: true });
      const result: any = yield call(Api.login, payload);
      yield put({
        type: actions.LOGIN_SUCCESS,
        remember: payload.remember,
        payload: result,
      });
      yield put({ type: appActions.IS_LOADING, payload: false });
    } catch (error) {
      yield put({
        type: appActions.HANDLE_ERRORS,
        payload: error,
      });
    }
  });
}

export function* logoutRequest() {
  yield takeEvery(actions.LOGOUT_REQUEST, function* () {
    try {
      yield put({ type: appActions.IS_LOADING, payload: true });
      const result: {} = yield call(Api.logout);
      const cookieName: string = yield select(getCookieName);
      yield put({ type: appActions.IS_LOADING, payload: false });
      yield put({
        type: actions.LOGOUT_SUCCESS,
        payload: cookieName,
        result: result,
      });
    } catch (error) {
      yield put({
        type: appActions.HANDLE_ERRORS,
        payload: error,
      });
    }
  });
}

export function* forgotPasswordRequest() {
  yield takeEvery(
    actions.FORGOT_PASSWORD_REQUEST,
    function* ({ payload, callback }: ForgotPasswordRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(Api.createResetPassword, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });

        if (Router.pathname.includes('/forgotPassword')) {
          Router.push({
            pathname: '/forgotPassword/Confirm',
            query: {
              email: payload.email,
              url: payload.url,
              icon: payload.icon,
            },
          });
        }

        if (callback) {
          callback();
        }

        yield put({
          type: actions.FORGOT_PASSWORD_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* resetPasswordRequest() {
  yield takeEvery(actions.RESET_PASSWORD_REQUEST, function* ({ payload }: Success) {
    try {
      const result: any = yield call(Api.resetPassword, payload);
      yield put({
        type: actions.RESET_PASSWORD_SUCCESS,
        payload: result,
      });
      Router.push({
        pathname: '/successpassword',
        query: { url: payload.url, icon: payload.icon },
      });
    } catch (error) {
      yield put({
        type: appActions.HANDLE_ERRORS,
        payload: error,
      });
    }
  });
}

export function* fetchCompaniesRequest() {
  yield takeEvery(
    actions.FETCH_COMPANIES_REQUEST,
    function* ({ payload }: FecthCompaniesRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(Api.fetchCompanies, payload);
        yield put({
          type: actions.FETCH_COMPANIES_SUCCESS,
          payload: result.data,
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* fetchCompaniesSuccess() {
  yield takeEvery(
    actions.FETCH_COMPANIES_SUCCESS,
    function* ({ payload }: EmpresasSuccess) {
      if (Object.keys(payload) == null) {
        redirect('/signin');
      }
    }
  );
}

export function* changeCompanyRequest() {
  yield takeEvery(actions.CHANGE_COMPANY_REQUEST, function* ({ payload }: any) {
    try {
      yield put({ type: appActions.IS_LOADING, payload: true });

      let result: {} = yield call(Api.changeCompany, payload);
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: result,
      });
      yield put({ type: appActions.IS_LOADING, payload: false });
    } catch (error) {
      yield put({
        type: appActions.HANDLE_ERRORS,
        payload: error,
      });
    }
  });
}

export function* updatePasswordRequest() {
  yield takeEvery(
    actions.UPDATE_PASSWORD_REQUEST,
    function* ({ payload, callback }: ActionUpdatePasswordRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(Api.updatePassword, payload);
        const { messages } = result;

        yield put({
          type: actions.UPDATE_PASSWORD_SUCCESS,
          payload,
          messages,
          isPasswordChange: !!('new_password' in payload),
        });

        if (callback) {
          callback();
        }

        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (err) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: err,
        });
      }
    }
  );
}

export function* completeRegistration() {
  yield takeEvery(
    actions.COMPLETE_REGISTRATION_REQUEST,
    function* ({ payload }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: { emai: string } = yield call(
          Api.completeRegistration,
          payload
        );
        yield put({ type: actions.COMPLETE_REGISTRATION_SUCCESS, payload: result });
        yield put({ type: appActions.IS_LOADING, payload: false });

        if (result) {
          Router.replace(`/?email=${payload.email}`);
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* verifyTokencompleteRegistration() {
  yield takeEvery(
    actions.VERIFY_TOKEN_COMPLETE_REGISTRATION_REQUEST,
    function* ({ payload }: any) {
      try {
        const result: { data: { valid: boolean } } = yield call(
          Api.verifyTokenCompleteRegistration,
          payload
        );

        if (!result.data.valid) throw new Error('Não está valido o token');
        yield put({ type: actions.VERIFY_TOKEN_COMPLETE_REGISTRATION_SUCCESS });
      } catch (error) {
        yield Router.push('/');
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: { error: 'Seu token é inválido ou já expirou' },
        });
      }
    }
  );
}

export function* updatePasswordSuccess() {
  yield takeEvery(
    actions.UPDATE_PASSWORD_SUCCESS,
    function* ({ messages, isPasswordChange, data }: any) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          message: messages[0],
        },
      });

      yield setCookie('id_token', data?.token);
      yield put({
        type: actions.SET_COOKIE_NAME,
        payload: 'id_token',
      });
    }
  );
}

export function* fetchTokenSuccess() {
  yield takeEvery(actions.FETCH_TOKEN_SUCCESS, function* ({ payload }: Success) {
    yield put({
      type: actions.SET_COOKIE_NAME,
      payload: `id_token`,
    });

    //@ts-ignore
    setCookie(`id_token`, payload.idToken);
  });
}

export function* forgotPasswordSuccess() {
  yield takeEvery(actions.FORGOT_PASSWORD_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        message: payload.messages[0],
      },
    });
  });
}

export function* loginSuccess() {
  yield takeEvery(
    actions.LOGIN_SUCCESS,
    function* ({ payload, remember, ctx }: Success) {
      if (payload.url) {
        return (window.location.href = payload.url);
      }
      yield put({
        type: actions.SET_PRIMEIRO_ACESSO,
        payload: payload.primeiro_acesso,
      });

      if (payload.token) {
        let queryParams = '?';
        queryParams += payload.exige_alterar_senha ? 'alterar_senha=true&' : '';
        queryParams += payload.primeiro_acesso ? 'primeiro_acesso=true&' : '';
        const decoded: Token = jwtDecode(payload.token);
        if (decoded) {
          yield setCookie('id_token', payload.token, remember);
          yield put({
            type: actions.SET_COOKIE_NAME,
            payload: 'id_token',
          });

          if (decoded.emp === 0) {
            redirect(ctx, '/empresa' + queryParams);
          } else if (decoded.emp !== 0) {
            if (window && window.innerWidth < 960) {
              return Router.replace('/visao-geral' + queryParams);
            }

            if (decoded.tipo == 0) {
              return Router.replace('/alunos' + queryParams);
            }

            if (decoded.tipo == 4) {
              return Router.replace('/instrutor' + queryParams);
            }

            if (payload.primeiro_acesso) {
              return Router.replace('/visao-geral' + queryParams);
            }

            if (decoded.tipo != 4) {
              yield put({
                type: appActions.SET_NPS_REQUEST,
              });
            }

            Router.replace('/alunos' + queryParams);
          }
        } else {
          yield put({
            type: actions.SET_COOKIE_NAME,
            payload: 'id_token',
          });

          redirect(ctx, '/alunos' + queryParams);

          yield put({
            type: appActions.SET_NPS_REQUEST,
          });
        }
      }
    }
  );
}

export function* logoutSuccess() {
  yield takeEvery(actions.LOGOUT_SUCCESS, function* ({ payload, result }: Success) {
    //@ts-ignore
    if (window.fcWidget) {
      //@ts-ignore
      window.fcWidget.user.setProperties({
        externalId: null,
        restoreId: null,
        firstName: null,
        email: null,
        siteId: null,
      });
      //@ts-ignore
      window.fcWidget.destroy();
    }
    yield put({ type: appActions.IS_LOADING, payload: true });

    yield removeCookie('id_token');

    if (!!result.site) {
      window.location.href = result.site.split(' ')[0];
    } else {
      Router.replace('/signin');
      window.location.reload();
    }
    yield put({ type: appActions.IS_LOADING, payload: false });
  });
}

export function* resetPasswordSuccess() {
  yield takeEvery(actions.RESET_PASSWORD_SUCCESS, function* ({}) {});
}

export default function* rootSaga() {
  yield all([
    spawn(fetchTokenRequest),
    spawn(redirectWithToken),
    spawn(completeRegistration),
    spawn(verifyTokencompleteRegistration),
    spawn(loginRequest),
    spawn(logoutRequest),
    spawn(forgotPasswordRequest),
    spawn(resetPasswordRequest),
    spawn(fetchCompaniesRequest),
    spawn(changeCompanyRequest),
    spawn(updatePasswordRequest),
    fork(fetchTokenSuccess),
    fork(loginSuccess),
    fork(logoutSuccess),
    fork(forgotPasswordSuccess),
    fork(updatePasswordSuccess),
    fork(fetchCompaniesSuccess),
    fork(resetPasswordSuccess),
    fork(redirectWithTokenSuccess),
  ]);
}
