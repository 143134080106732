import actions from './actions';

const initState = {
  pageData: {
    logo: '',
    icone: '',
    banner: '',
    tem_icone: false,
    latitude: 0.0,
    longitude: 0.0,
    tem_logo: false,
    whatsapp: null,
    instagram: null,
    facebook: null,
    youtube: null,
    twitter: null,
  },
  contract: null,
  print: null,
  listContracts: {
    data: [],
    meta: [],
  },
  listPrints: {
    data: [],
    meta: [],
  },
  listProviders: {
    data: [],
  },
  employee: null,
  contractResult: null,
  printResult: null,
  listRegisterEmployees: {
    data: [],
    pageController: 0,
    meta: [],
  },
  condicaoPagamento: null,
};

export default function configReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.SET_EMPLOYEE: {
      return {
        ...state,
        employee: action.payload,
      };
    }

    case actions.FETCH_LIST_EMPLOYEES_REGISTER_SUCCESS: {
      return {
        ...state,
        listRegisterEmployees: {
          pageController: action.payload.data.length,
          data:
            action.payload.meta.current_page > 1
              ? [...state.listRegisterEmployees.data, ...action.payload.data]
              : action.payload.data,
          meta: action.payload.meta,
        },
        employee: null,
      };
    }

    case actions.PAGE_DATA_SUCCESS: {
      return {
        ...state,
        pageData: {
          ...action.payload.data,
          banner: action.payload.data.imagem_principal,
        },
      };
    }

    case actions.INSERT_LOGO: {
      if (state.pageData.logo) {
        return {
          ...state,
          pageData: {
            ...state.pageData,
            logo: action.payload,
            tem_logo: true,
          },
        };
      } else {
        return {
          ...state,
          pageData: {
            ...state.pageData,
            logo: action.payload,
            tem_logo: false,
          },
        };
      }
    }

    case actions.INSERT_BANNER: {
      if (state.pageData.banner) {
        return {
          ...state,
          pageData: {
            ...state.pageData,
            banner: action.payload,
            tem_banner: true,
          },
        };
      } else {
        return {
          ...state,
          pageData: {
            ...state.pageData,
            banner: action.payload,
            tem_banner: false,
          },
        };
      }
    }

    case actions.INSERT_ICON: {
      if (state.pageData.icone) {
        return {
          ...state,
          pageData: {
            ...state.pageData,
            icone: action.payload,
            tem_icone: true,
          },
        };
      } else {
        return {
          ...state,
          pageData: {
            ...state.pageData,
            icone: action.payload,
            tem_icone: false,
          },
        };
      }
    }

    case actions.INSERT_MARKER: {
      return {
        ...state,
        pageData: {
          ...state.pageData,
          latitude: action.payload.lat,
          longitude: action.payload.lng,
        },
      };
    }

    case actions.SET_CONTRACT: {
      return {
        ...state,
        contract: action.payload,
      };
    }

    case actions.SET_PRINT: {
      return {
        ...state,
        print: action.payload,
      };
    }

    case actions.FETCH_LIST_PROVIDERS_SUCCESS: {
      return {
        ...state,
        listProviders: {
          data: action.payload.data,
        },
      };
    }

    case actions.FETCH_CONTRACT_SUCCESS: {
      return {
        ...state,
        contractResult: action.payload.data,
      };
    }

    case actions.FETCH_PRINT_SUCCESS: {
      return {
        ...state,
        printResult: action.payload.data,
      };
    }

    case actions.FETCH_LIST_CONTRACTS_SUCCESS: {
      return {
        ...state,
        listContracts: {
          data: action.payload.data,
          meta: action.payload.meta,
        },
      };
    }

    case actions.FETCH_LIST_PRINTS_SUCCESS: {
      return {
        ...state,
        listPrints: {
          data: action.payload.data,
          meta: action.payload.meta,
        },
      };
    }

    case actions.INSERT_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employee: action.payload.data,
      };
    }

    case actions.UPDATE_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employee: action.payload.data,
      };
    }

    case actions.FETCH_CONDICAO_PAGAMENTO_SUCCESS: {
      return {
        ...state,
        condicaoPagamento: action.payload.data,
      };
    }

    default:
      return state;
  }
}
