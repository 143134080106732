import { Options } from '../types';
import { ProccessType } from './types';

const actions = {
  FETCH_LIST_PROCCESS_REQUEST: 'FETCH_LIST_PROCCESS_REQUEST',
  FETCH_LIST_PROCCESS_SUCCESS: 'FETCH_LIST_PROCCESS_SUCCESS',
  INSERT_PROCCESS_REQUEST: 'INSERT_PROCCESS_REQUEST',
  INSERT_PROCCESS_SUCCESS: 'INSERT_PROCCESS_SUCCESS',
  UPDATE_PROCCESS_REQUEST: 'UPDATE_PROCCESS_REQUEST',
  UPDATE_PROCCESS_SUCCESS: 'UPDATE_PROCCESS_SUCCESS',
  INACTIVE_PROCCESS_REQUEST: 'INACTIVE_PROCCESS_REQUEST',
  INACTIVE_PROCCESS_SUCCESS: 'INACTIVE_PROCCESS_SUCCESS',
  DELETE_PROCCESS_REQUEST: 'DELETE_PROCCESS_REQUEST',
  DELETE_PROCCESS_SUCCESS: 'DELETE_PROCCESS_SUCCESS',
  SET_PROCCESS: 'SET_PROCCESS',

  setProccess: (payload: number) => ({
    type: actions.SET_PROCCESS,
    payload: payload,
  }),

  fetchListProccess: (payload: Options) => ({
    type: actions.FETCH_LIST_PROCCESS_REQUEST,
    payload,
  }),

  insertProccess: (payload: ProccessType, callback: () => void) => ({
    type: actions.INSERT_PROCCESS_REQUEST,
    payload,
    callback,
  }),

  updateProccess: (payload: ProccessType, callback: () => void) => ({
    type: actions.UPDATE_PROCCESS_REQUEST,
    payload,
    callback,
  }),

  deleteProccess: (process: number, params: Options, callback: () => void) => ({
    type: actions.DELETE_PROCCESS_REQUEST,
    payload: process,
    params: params,
    callback,
  }),

  inactiveProccess: (process: number, status: number, params: Options, callback: () => void) => ({
    type: actions.INACTIVE_PROCCESS_REQUEST,
    payload: process,
    status: status,
    params: params,
    callback,
  }),
};
export default actions;
