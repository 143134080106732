import actions from './actions';

const initialState = {
  listVendas: {
    data: [],
    meta: {},
    extra: {},
  },
  listVendaRapida: {
    data: [],
    meta: {},
    pageController: 0,
  },
};

export default function VendaReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.INSERT_VENDA_SUCCESS: {
      return { ...state, venda: action.payload.data };
    }

    case actions.APROVAR_VENDA_SUCCESS: {
      return { ...state, venda: action.payload.data };
    }

    case actions.FETCH_LIST_VENDAS_SUCCESS: {
      return {
        ...state,
        listVendas: {
          extra: action.payload.extra,
          meta: action.payload.meta,
          pageController: action.payload.data.length,
          data:
            action.payload.meta.current_page > 1
              ? [...state.listVendas.data, ...action.payload.data]
              : action.payload.data,
        },
      };
    }

    case actions.FETCH_LIST_VENDA_RAPIDA_SUCCESS: {
      return {
        ...state,
        listVendaRapida: {
          meta: action.payload.meta,
          pageController: action.payload.data.length,
          data:
            action.payload.meta.current_page > 1
              ? [...state.listVendaRapida.data, ...action.payload.data]
              : action.payload.data,
        },
      };
    }

    case actions.FETCH_VENDA_SUCCESS: {
      return { ...state, venda: action.payload };
    }

    default:
      return state;
  }
}
