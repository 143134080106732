import { all, takeEvery, put, call, spawn } from 'redux-saga/effects';

import appActions from '../app/actions';
import actions from './actions';
import api from '../../helpers/api';

import { actionFetchScheduleRequest } from './types';

export function* fetchScheduleRequest() {
  yield takeEvery(
    actions.FETCH_CONFIG_SCHEDULE_REQUEST,
    function* ({}: actionFetchScheduleRequest) {
      try {
        const result: any = yield call(api.fetchConfigSchedule);

        yield put({
          type: actions.FETCH_CONFIG_SCHEDULE_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export default function* rootSaga() {
  yield all([spawn(fetchScheduleRequest)]);
}
