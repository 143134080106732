import { Options } from '../types';

const actions = {
  FETCH_LIST_PERIODS_REQUEST: 'FETCH_LIST_PERIODS_REQUEST',
  FETCH_LIST_PERIODS_SUCCESS: 'FETCH_LIST_PERIODS_SUCCESS',
  FETCH_PERIOD_REQUEST: 'FETCH_PERIOD_REQUEST',
  FETCH_PERIOD_SUCCESS: 'FETCH_PERIOD_SUCCESS',
  INSERT_PERIOD_REQUEST: 'INSERT_PERIOD_REQUEST',
  INSERT_PERIOD_SUCCESS: 'INSERT_PERIOD_SUCCESS',
  UPDATE_PERIOD_REQUEST: 'UPDATE_PERIOD_REQUEST',
  UPDATE_PERIOD_SUCCESS: 'UPDATE_PERIOD_SUCCESS',
  DELETE_PERIOD_REQUEST: 'DELETE_PERIOD_REQUEST',
  DELETE_PERIOD_SUCCESS: 'DELETE_PERIOD_SUCCESS',
  SET_PERIOD: 'SET_PERIOD',
  SET_FILTERS: 'SET_FILTERS',

  fetchListPeriods: (payload: Options) => ({
    type: actions.FETCH_LIST_PERIODS_REQUEST,
    payload,
  }),

  fetchPeriod: (PeriodID: number) => ({
    type: actions.FETCH_PERIOD_REQUEST,
    payload: PeriodID,
  }),

  insertPeriod: (payload: any, callback: () => void) => ({
    type: actions.INSERT_PERIOD_REQUEST,
    payload,
    callback,
  }),

  updatePeriod: (payload: any, callback: () => void) => ({
    type: actions.UPDATE_PERIOD_REQUEST,
    payload,
    callback,
  }),

  deletePeriod: (PeriodID: number, params: Options, callback: () => void) => ({
    type: actions.DELETE_PERIOD_REQUEST,
    payload: PeriodID,
    params,
    callback,
  }),

  setPeriod: (PeriodID: number) => ({
    type: actions.SET_PERIOD,
    payload: PeriodID,
  }),

  setFilters: (payload: any) => ({
    type: actions.SET_FILTERS,
    payload: payload,
  }),
};

export default actions;
