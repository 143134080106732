import { Options } from '../types';

const actions = {
  FETCH_LIST_VEHICLES_REQUEST: 'FETCH_LIST_VEHICLES_REQUEST',
  FETCH_LIST_VEHICLES_SUCCESS: 'FETCH_LIST_VEHICLES_SUCCESS',
  FETCH_VEHICLE_REQUEST: 'FETCH_VEHICLE_REQUEST',
  FETCH_VEHICLE_SUCCESS: 'FETCH_VEHICLE_SUCCESS',
  INSERT_VEHICLE_REQUEST: 'INSERT_VEHICLE_REQUEST',
  INSERT_VEHICLE_SUCCESS: 'INSERT_VEHICLE_SUCCESS',
  UPDATE_VEHICLE_REQUEST: 'UPDATE_VEHICLE_REQUEST',
  UPDATE_VEHICLE_SUCCESS: 'UPDATE_VEHICLE_SUCCESS',
  INACTIVE_VEHICLE_REQUEST: 'INACTIVE_VEHICLE_REQUEST',
  INACTIVE_VEHICLE_SUCCESS: 'INACTIVE_VEHICLE_SUCCESS',
  DELETE_VEHICLE_REQUEST: 'DELETE_VEHICLE_REQUEST',
  DELETE_VEHICLE_SUCCESS: 'DELETE_VEHICLE_SUCCESS',
  SET_VEHICLE: 'SET_VEHICLE',

  fetchListVehicles: (payload: Options) => ({
    type: actions.FETCH_LIST_VEHICLES_REQUEST,
    payload,
  }),

  fetchVehicle: (vehicleID: number) => ({
    type: actions.FETCH_VEHICLE_REQUEST,
    payload: vehicleID,
  }),

  insertVehicle: (payload: any, callback: (payload: any) => void) => ({
    type: actions.INSERT_VEHICLE_REQUEST,
    payload,
    callback,
  }),

  updateVehicle: (payload: any, callback: () => void) => ({
    type: actions.UPDATE_VEHICLE_REQUEST,
    payload,
    callback,
  }),

  deleteVehicle: (vehicleID: number, params: Options, callback: () => void) => ({
    type: actions.DELETE_VEHICLE_REQUEST,
    payload: vehicleID,
    params,
    callback,
  }),

  inactiveVehicle: (
    vehicleID: number,
    status: number,
    params: Options,
    callback: () => void
  ) => ({
    type: actions.INACTIVE_VEHICLE_REQUEST,
    payload: vehicleID,
    status,
    params,
    callback,
  }),

  setVehicle: (vehicleID: number) => ({
    type: actions.SET_VEHICLE,
    payload: vehicleID,
  }),
};

export default actions;
