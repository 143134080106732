import React from 'react';
import * as Sentry from '@sentry/react';

//next
import App from 'next/app';
import Head from 'next/head';

//redux
import { withReduxSaga } from '../src/redux/store';
import { Provider } from 'react-redux';
import moment from 'moment';

//components
import { getCookie } from '../src/helpers/session';
import progressBar from '../src/helpers/routerProgress';
import ReactGA from 'react-ga';

moment.locale('pt-br');
progressBar();

if (process.env.NODE_ENV === 'production') {
  if (Sentry && Sentry.init && typeof Sentry.init === 'function') {
    Sentry.init({
      environment: process.env.NODE_ENV,
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'Failed to fetch',
      ],
      dsn:
        process.env.SENTRY_DSN ||
        'https://97e9d61ead444de59810082a7799de2d@o206122.ingest.sentry.io/5338462',
      normalizeDepth: 10,
    });
  }
}

//FULL-CALENDAR
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import { tagHotjar } from '../src/utils/AllUtils';

class MyApp extends App<{ [name: string]: any }> {
  constructor(props: any) {
    super(props);
    this.state = {
      store: {},
    };
  }

  static async getInitialProps({
    Component,
    ctx,
  }: {
    Component: any;
    ctx: any;
  }) {
    let cookieName = ctx.store.getState().AuthReducer.cookieName;
    const token = getCookie(cookieName, ctx.req);
    if (token) {
      ctx.store.dispatch({
        type: 'FETCH_TOKEN_REQUEST',
        payload: token,
        ctx,
      });
    }
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }

  static getDerivedStateFromProps(props: any) {
    return { store: props.store };
  }

  componentDidUpdate() {
    //@ts-ignore
    if (window && window.fcWidget) {
      //@ts-ignore
      window.fcWidget.on('widget:closed', function () {
        //@ts-ignore
        window.fcWidget.hide();
      });
    }
  }

  componentDidMount() {
    const { store } = this.props;

    const cookieName = store.getState().AuthReducer.cookieName;

    ReactGA.initialize('UA-157028837-1');

    const token = getCookie(cookieName);
    if (token) {
      store.dispatch({
        type: 'FETCH_TOKEN_REQUEST',
        payload: token,
      });
    }

    if (window) {
      window.addEventListener(
        'keydown',
        (e) => {
          if (
            document.querySelector('.line-circular-loader') &&
            e?.key == 'Enter'
          ) {
            if (
              //@ts-ignore
              e.target?.nodeName === 'INPUT' ||
              //@ts-ignore
              e.target?.nodeName === 'BUTTON'
            ) {
              e.preventDefault();
              return false;
            }
          } else if (
            document.querySelector('.line-circular-loader') &&
            e?.code == 'Space'
          ) {
            //@ts-ignore
            if (e.target?.nodeName === 'BUTTON') {
              e.preventDefault();
              return false;
            }
          }
        },
        true
      );
    }

    Sentry.configureScope((scope: any) => {
      scope.setUser({
        username: store.getState().AuthReducer.profile.nome_pessoa,
        id: store.getState().AuthReducer.profile.emp,
        email: store.getState().AuthReducer.profile.email,
        empNome: store.getState().AuthReducer.profile.razao_social,
      });
    });
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope: any) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });

        window.location.pathname = '/500';
        tagHotjar(String(error));
        tagHotjar('ERROR REACT');
        Sentry.captureException(error);
      });
    }
  }

  render() {
    const { Component, pageProps, store } = this.props;
    return (
      <>
        <Head>
          <title>iCFC</title>
        </Head>
        <script src='https://wchat.freshchat.com/js/widget.js?reload=3f7hw7h'></script>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'
        />
        <Provider store={store}>
          {typeof window != 'undefined' && (
            <Component {...pageProps} />
          )}
        </Provider>
      </>
    );
  }
}

export default withReduxSaga(MyApp);
