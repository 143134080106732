import { all, takeEvery, put, call, fork, spawn, select } from 'redux-saga/effects';

import appActions from '../app/actions';
import actions from './actions';
import api from '../../helpers/api';

//TYPES
import {
  actionFetchListProccessRequest,
  actionInsertProccessRequest,
  actionUpdateProccessRequest,
  actionDeleteProccessRequest,
  actionInactiveProccessRequest,
  actionInactiveProccessSuccess,
} from './types';
import { SUCESSS } from '../config/types';
import { Meta } from '../types';

export function* getListagemProccessRequest() {
  yield takeEvery(
    actions.FETCH_LIST_PROCCESS_REQUEST,
    function* ({ payload }: actionFetchListProccessRequest) {
      try {
        const result: { meta: Meta; data: any } = yield call(
          api.fetchProccess,
          payload
        );

        yield put({
          type: actions.FETCH_LIST_PROCCESS_SUCCESS,
          payload: result,
        });

        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertProccessRequest() {
  yield takeEvery(
    actions.INSERT_PROCCESS_REQUEST,
    function* ({ payload, callback }: actionInsertProccessRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.insertProccess, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.INSERT_PROCCESS_SUCCESS,
          payload: result,
        });
        callback();
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* updateProccessRequest() {
  yield takeEvery(
    actions.UPDATE_PROCCESS_REQUEST,
    function* ({ payload, callback }: actionUpdateProccessRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.updateProccess, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.UPDATE_PROCCESS_SUCCESS,
          payload: result,
        });
        callback();
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* inactiveProccessRequest() {
  yield takeEvery(
    actions.INACTIVE_PROCCESS_REQUEST,
    function* ({
      payload,
      status,
      params,
      callback,
    }: actionInactiveProccessRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.inactiveProccess, payload, status);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.INACTIVE_PROCCESS_SUCCESS,
          params: params,
        });
        callback();
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deleteProccessRequest() {
  yield takeEvery(
    actions.DELETE_PROCCESS_REQUEST,
    function* ({ payload, params, callback }: actionDeleteProccessRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.deleteProccess, payload);
        yield put({
          type: actions.DELETE_PROCCESS_SUCCESS,
          payload: result,
          params: params,
        });
        callback();
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertProccessSuccess() {
  yield takeEvery(actions.INSERT_PROCCESS_SUCCESS, function* ({ payload }: SUCESSS) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true },
    });
  });
}

export function* updateProccessSuccess() {
  yield takeEvery(actions.UPDATE_PROCCESS_SUCCESS, function* ({ payload }: SUCESSS) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true },
    });
  });
}

export function* deleteProccessSuccess() {
  yield takeEvery(
    actions.DELETE_PROCCESS_SUCCESS,
    function* ({ payload, params }: SUCESSS) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true },
      });
      yield put({
        type: actions.FETCH_LIST_PROCCESS_REQUEST,
        payload: params,
      });
    }
  );
}

export function* inactiveProccessSuccess() {
  yield takeEvery(
    actions.INACTIVE_PROCCESS_SUCCESS,
    function* ({ params }: actionInactiveProccessSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true },
      });
      yield put({
        type: actions.FETCH_LIST_PROCCESS_REQUEST,
        payload: params,
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(getListagemProccessRequest),
    spawn(insertProccessRequest),
    spawn(deleteProccessRequest),
    spawn(inactiveProccessRequest),
    spawn(updateProccessRequest),
    fork(insertProccessSuccess),
    fork(updateProccessSuccess),
    fork(inactiveProccessSuccess),
    fork(deleteProccessSuccess),
  ]);
}
